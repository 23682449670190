import { useWizard } from '@wow/wizard';
import { useEffect, useMemo, useState } from 'react';

import { getOperatorsSignature, getPermissions } from '../../services/api';
import { AccountSelected } from '../interfaces/AccountsSelected';
import { IContextProviderProps } from '../interfaces/IAppContext';
import { MutationSignersValidate } from '../interfaces/IMutationSignersValidate';
import { Operator } from '../interfaces/Operator';
import AppContext from './Provider';

function AppContextProvider({ children }: IContextProviderProps) {
  const initialData = useMemo(
    () => ({
      itemsColumnOne: JSON.parse(sessionStorage.getItem('itemsColumnOne') || '[]'),
      itemsColumnTwo: JSON.parse(sessionStorage.getItem('itemsColumnTwo') || '[]'),
      itemsColumnThree: JSON.parse(sessionStorage.getItem('itemsColumnThree') || '[]'),
    }),
    [],
  );

  const [itemsColumnOne, setItemsColumnOne] = useState<Operator[]>(initialData.itemsColumnOne);
  const [itemsColumnTwo, setItemsColumnTwo] = useState<Operator[]>(initialData.itemsColumnTwo);
  const [itemsColumnThree, setItemsColumnThree] = useState<Operator[]>(initialData.itemsColumnThree);
  const [data, setData] = useState<Operator[]>([]);
  const [filteredData, setFilteredData] = useState<Operator[]>([]);
  const [signersSchema, setSignersSchema] = useState<MutationSignersValidate>({
    signerList: [],
  });
  const [permissions, setPermissions] = useState(null);
  const [initialized, setInitialized, currentStep, setCurrentStep] = useWizard();
  const [modalWelcome, setModalWelcome] = useState<boolean>(false);
  const [whiteList, setWhiteList] = useState<boolean>(false);

  useEffect(() => {
    getPermissions()
      .then(res => {
        setPermissions(res.data);
      })
      .catch(err => {
        console.error('Error fetching permissions:', err);
      });
  }, []);

  const [selectedItemsAccount, setSelectedItemsAccount] = useState<AccountSelected[]>([]);

  const [errorResponse, setErrorResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const resetColumns = () => {
    setItemsColumnOne([]);
    setItemsColumnTwo([]);
    setItemsColumnThree([]);
  };

  const fetchData = async () => {
    try {
      const response = await getOperatorsSignature();
      if (Array.isArray(response.data.data)) {
        const sortData = response.data.data;
        setData(sortData.sort((a, b) => a.firstName.localeCompare(b.firstName) || a.lastName.localeCompare(b.lastName)));
        setFilteredData(response.data.data);
      } else {
        throw new Error('Invalid data format');
      }
    } catch (error) {
      if (error.response?.status === 404) {
        setErrorResponse('No data found (404)');
      } else {
        setErrorResponse('An error occurred');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const validateSchema = () => {
    setSignersSchema(() => {
      const signerGroups = [
        itemsColumnOne.map(item => ({
          documentNumber: item.documentNumber,
          countryCode: item.countryCode,
        })),
        itemsColumnTwo.map(item => ({
          documentNumber: item.documentNumber,
          countryCode: item.countryCode,
        })),
        itemsColumnThree.map(item => ({
          documentNumber: item.documentNumber,
          countryCode: item.countryCode,
        })),
      ];

      const filteredSignerGroups = signerGroups.filter(group => group.length > 0);

      const updatedItem: MutationSignersValidate = {
        signerList: filteredSignerGroups.map(signers => ({ signers })),
      };

      return updatedItem;
    });
  };

  const accountsCandidateValues = {
    candidateSigners: signersSchema.signerList.flatMap(item => item.signers),
  };

  const [showErrorContent, setShowErrorContent] = useState(false);

  const value = useMemo(
    () => ({
      itemsColumnOne,
      setItemsColumnOne,
      itemsColumnTwo,
      setItemsColumnTwo,
      itemsColumnThree,
      setItemsColumnThree,
      resetColumns,
      data,
      setData,
      filteredData,
      setFilteredData,
      signersSchema,
      setSignersSchema,
      validateSchema,
      accountsCandidateValues,
      showErrorContent,
      setShowErrorContent,
      selectedItemsAccount,
      setSelectedItemsAccount,
      errorResponse,
      setErrorResponse,
      isLoading,
      setIsLoading,
      fetchData,
      permissions,
      initialized,
      setInitialized,
      currentStep,
      setCurrentStep,
      modalWelcome,
      setModalWelcome,
      whiteList,
      setWhiteList,
    }),
    [
      itemsColumnOne,
      itemsColumnTwo,
      itemsColumnThree,
      data,
      filteredData,
      signersSchema,
      showErrorContent,
      selectedItemsAccount,
      errorResponse,
      isLoading,
      permissions,
      initialized,
      setInitialized,
      currentStep,
      setCurrentStep,
      modalWelcome,
      setModalWelcome,
      whiteList,
      setWhiteList,
    ],
  );

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

export default AppContextProvider;

/* eslint-disable import/prefer-default-export */
import { Card, CardSection } from '@wow/card';
import styled from 'styled-components';

import { body14bold } from '../../styled/generalStyles.';

export const StyledCard = styled(Card)`
  height: 512px;
  outline: none;
  outline-offset: 0px;
  border: none !important;
  background-color: #ffffff !important;
  padding: 20px;
  overflow: auto;

  ${props =>
    props.backgroundBlue &&
    `
       background-color:  #e8f1fc !important;
    `}

  ${props =>
    props.borderDottedBold &&
    `
      outline: 1px dashed #B2B7C4;
    `}

  ${props =>
    props.borderDotted &&
    `
      outline: 1px dashed rgba(178, 183, 196, 0.40);
    `}

  ${props =>
    props.borderSolid &&
    `
      outline: 1px solid #798298;
    `}

${props =>
    props.borderDottedBlue &&
    `
      outline: 2px dashed #2172DC; // Azul
    `}
`;

export const CardSectionHorizontal = styled(CardSection)`
  padding: 0 !important;
`;

export const CenteredButton = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const TextContainer = styled.div`
  & > *:not(:last-child) {
    margin-bottom: 8px;
  }
  margin-bottom: 16px;
  color: ${props => (props.colorDarkTitle ? '#565E71' : '#B2B7C4')};
`;

export const TextBold = styled.span`
  ${body14bold};
`;

export const CardSectionHorizontalWithSignators = styled(CardSection)`
  padding: 0 !important;
`;

export const SignatorsCard = styled.div`
  .container {
    background: #e8f1fc;
    height: 3.5rem;
    border-radius: 8px;
    margin: 1rem 0;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    ${props =>
      props.styleWithCheckbox &&
      `
      border: 1px solid #94BCF0;
    `}
  }
`;

import { Operator } from '../interfaces/Operator';

const operatorsMock: Operator[] = [
  {
    countryCode: 'AR',
    documentNumber: '1',
    firstName: 'Persona',
    lastName: '1',
    operatorId: 1,
  },
  {
    countryCode: 'AR',
    documentNumber: '2',
    firstName: 'Persona',
    lastName: '2',
    operatorId: 2,
  },
  {
    countryCode: 'AR',
    documentNumber: '3',
    firstName: 'Persona',
    lastName: '3',
    operatorId: 3,
  },
  {
    countryCode: 'AR',
    documentNumber: '4',
    firstName: 'Persona',
    lastName: '4',
    operatorId: 4,
  },
  {
    countryCode: 'AR',
    documentNumber: '5',
    firstName: 'Persona',
    lastName: '5',
    operatorId: 5,
  },
  {
    countryCode: 'AR',
    documentNumber: '6',
    firstName: 'Persona',
    lastName: '6',
    operatorId: 6,
  },
  {
    countryCode: 'AR',
    documentNumber: '7',
    firstName: 'Persona',
    lastName: '7',
    operatorId: 7,
  },
  {
    countryCode: 'AR',
    documentNumber: '8',
    firstName: 'Persona',
    lastName: '8',
    operatorId: 8,
  },
  {
    countryCode: 'AR',
    documentNumber: '9',
    firstName: 'Persona',
    lastName: '9',
    operatorId: 9,
  },
];
export default operatorsMock;

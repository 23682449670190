function Moon() {
  return (
    <svg width='80' height='80' viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M49 59C37.544 59 27.3 51.932 23.08 41.468C26.629 43.7715 30.769 44.9982 35 45C47.13 45 57 35.13 57 23C57.007 18.915 55.8733 14.9092 53.7267 11.4336C51.5801 7.95804 48.5058 5.1508 44.85 3.328C46.2228 3.11176 47.6103 3.0021 49 3C64.44 3 77 15.56 77 31C77 46.44 64.44 59 49 59Z'
        fill='#E8F1FC'
      />
      <path
        d='M49 4C63.888 4 76 16.112 76 31C76 45.888 63.888 58 49 58C38.988 58 29.938 52.402 25.3 43.84C28.336 45.2621 31.6474 45.9995 35 46C47.682 46 58 35.682 58 23C58.006 19.2607 57.0978 15.5767 55.3545 12.2687C53.6113 8.96066 51.0858 6.12891 47.998 4.02C48.334 4.006 48.668 4 49 4ZM49 2C46.418 2 43.928 2.37 41.546 3.002C42.168 3.202 42.666 3.406 43.076 3.614C50.664 6.778 56 14.264 56 23C56 34.598 46.598 44 35 44C29.8702 44.0013 24.9192 42.1155 21.09 38.702C24.47 50.97 35.658 60 49 60C65.016 60 78 47.016 78 31C78 14.984 65.016 2 49 2ZM10.25 19.75L2 17L10.25 14.25L13 6L15.75 14.25L24 17L15.75 19.75L13 28L10.25 19.75Z'
        fill='#2172DC'
      />
      <path
        d='M34.164 19.828L32.036 19.118L34.164 18.41L34.874 16.28L35.582 18.41L37.71 19.118L35.582 19.828L34.874 21.956L34.164 19.828Z'
        fill='#E8F1FC'
      />
      <path
        d='M34.874 13.118L33.374 17.618L28.874 19.118L33.374 20.618L34.874 25.118L36.374 20.618L40.874 19.118L36.374 17.618L34.874 13.118Z'
        fill='#2172DC'
      />
      <path
        d='M73 68H68.97C69.606 67.16 70 66.128 70 65C70 62.25 67.75 60 65 60H52.97C53.606 59.16 54 58.128 54 57C54 55.872 53.606 54.84 52.97 54H53C55.75 54 58 51.75 58 49C58 46.25 55.75 44 53 44H34.97C35.606 43.16 36 42.128 36 41C36 38.25 33.75 36 31 36H7C4.25 36 2 38.25 2 41C2 43.75 4.25 46 7 46H9.03C8.394 46.84 8 47.872 8 49C8 50.278 8.5 51.434 9.294 52.32C8.33245 52.6699 7.50123 53.3061 6.91228 54.1429C6.32333 54.9796 6.00494 55.9768 6 57C6 58.128 6.394 59.16 7.03 60H7C4.25 60 2 62.25 2 65C2 67.75 4.25 70 7 70H15.03C14.394 70.84 14 71.872 14 73C14 75.75 16.25 78 19 78H73C75.75 78 78 75.75 78 73C78 70.25 75.75 68 73 68Z'
        fill='#94BCF0'
      />
      <path
        d='M21.09 38.702C24.47 50.97 35.658 60 49 60C50.418 60 51.8078 59.8899 53.1698 59.6899C53.6798 58.9099 54 57.996 54 57C54 55.872 53.606 54.84 52.97 54H53C55.75 54 58 51.75 58 49C58 46.25 55.75 44 53 44H34.97L34.9698 43.9979C29.8505 43.9931 24.9114 42.1086 21.09 38.702Z'
        fill='#BBD5F6'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M68.97 68H73C75.75 68 78 70.25 78 73C78 75.75 75.75 78 73 78H19C16.25 78 14 75.75 14 73C14 71.872 14.394 70.84 15.03 70H7C4.25 70 2 67.75 2 65C2 62.25 4.25 60 7 60H7.03C6.394 59.16 6 58.128 6 57C6.00494 55.9768 6.32333 54.9796 6.91228 54.1429C7.50123 53.3061 8.33245 52.6699 9.294 52.32C8.5 51.434 8 50.278 8 49C8 47.872 8.394 46.84 9.03 46H7C4.25 46 2 43.75 2 41C2 38.25 4.25 36 7 36H31C33.75 36 36 38.25 36 41C36 42.128 35.606 43.16 34.97 44H53C55.75 44 58 46.25 58 49C58 51.75 55.75 54 53 54H52.97C53.606 54.84 54 55.872 54 57C54 58.128 53.606 59.16 52.97 60H65C67.75 60 70 62.25 70 65C70 66.128 69.606 67.16 68.97 68ZM16 73C16 74.654 17.346 76 19 76H73C74.654 76 76 74.654 76 73C76 71.346 74.654 70 73 70H63C62.7349 69.9995 62.4809 69.8939 62.2935 69.7065C62.1061 69.5191 62.0005 69.2651 62 69C62 68.45 62.45 68 63 68H65C66.654 68 68 66.654 68 65C68 63.346 66.654 62 65 62H47C46.7349 61.9995 46.4809 61.8939 46.2935 61.7065C46.1061 61.5191 46.0005 61.2651 46 61C46 60.45 46.45 60 47 60H49C50.654 60 52 58.654 52 57C52 55.346 50.654 54 49 54H47C46.7349 53.9995 46.4809 53.8939 46.2935 53.7065C46.1061 53.5191 46.0005 53.2651 46 53C46 52.45 46.45 52 47 52H53C54.654 52 56 50.654 56 49C56 47.346 54.654 46 53 46H29C28.7349 45.9995 28.4809 45.8939 28.2935 45.7065C28.1061 45.5191 28.0005 45.2651 28 45C28 44.45 28.45 44 29 44H31C32.654 44 34 42.654 34 41C34 39.346 32.654 38 31 38H7C5.346 38 4 39.346 4 41C4 42.654 5.346 44 7 44H15C15.55 44 16 44.45 16 45C16 45.55 15.55 46 15 46H13C11.346 46 10 47.346 10 49C10 50.654 11.346 52 13 52H15C15.55 52 16 52.45 16 53C16 53.55 15.55 54 15 54H11C9.346 54 8 55.346 8 57C8 58.654 9.346 60 11 60H13C13.55 60 14 60.45 14 61C14 61.55 13.55 62 13 62H7C5.346 62 4 63.346 4 65C4 66.654 5.346 68 7 68H21C21.55 68 22 68.45 22 69C22 69.55 21.55 70 21 70H19C17.346 70 16 71.346 16 73Z'
        fill='#2172DC'
      />
    </svg>
  );
}

export default Moon;

// COLORES

export const colors = {
  light: '#74777F',
  lightgray: '#44474F',
  gray: 'rgba(24, 25, 27, 0.12)',
  white: 'white',
  wh: '#FDFBFF',
  backGray: 'rgba(56, 56, 56, 0.38)',
  textgray: '#2F3034',
  blackAlpha: 'blackAlpha.700',
  blue: '#2172DC',
  danger: '#cf0031',
  error: '#f03c3c',
  borderProof: '#00789e',
  proofIcon: '#00789E',
  backgroundProof: '#B9EDFE',
  warning: '#946500',
  yellowLight: '#FFF4E5',
  bug: '#FFDDE5',
  brandpurple: '#5b31b2',
  borderGray: '#c7c6ca',
  transparent: 'transparent',
  red: '#F03C3C',
  violetLight: '#E8E0F9',
  rowBackgroundTable: 'rgba(33, 114, 220, 0.12)',
  rowHeaderBackgroundTable: '#f2f0f4',
  lightGreen: '#D0FCF0',
  green: '#037D5C',
  lightYellow: '#FFF4E5',
  brown: '#946500',
  black: '#000000',
  disabled: '#ACABAF',
  sucess: '#109B75',
  lightPurple: '#F6EFFF',
  lightBlueNew: '#EBF0FF',
  primaryBlue: 'rgba(74, 143, 251, 0.3)',
  primaryHover: '#EBF0FF',
  lightBlue: '#4A8FFB',
  LavenderGray: '#C9CAC6',
  AzureishWhite: '#d6e2ff',
  OuterSpace: 'rgba(68, 71, 79, 0.12)',
  AliceBlue: '#ebf0ff',
  Gainsboro: '#d6dcea',
  onSecondaryHover: '#565E711F',
  onSurfaceVariantB: '#5C5E66',
  grayb3: 'B3B3B3',
  disabledTextColor: 'rgba(56, 56, 56, 0.38)',
  InfoBETA50: '#B9EDFE',
  InfoBETA120: '#037193',
  Secondary100: '#565E71',
  Secondary50: '#DAE2F9',
  BlueLight: '#D6E2FF',
  BlueDark: '#002E68',
  gray4: '#C7C6CA',
  gray5: '#BEC6DC',
  gray6: '#E3E2E6',
  gray7: '#EFF0FA',
  error110: '#BA1B1B',
};

export const fonts = {
  heading: 'Red Hat Display, sans-serif',
  headingSemiBold: 'Red Hat Display Semibold, sans-serif',
  headingBold: 'Red Hat Display Bold, sans-serif',
  body: 'Inter, sans-serif',
  bodySemiBold: 'Inter Semibold, sans-serif',
  bodyBold: 'Inter Bold, sans-serif',
  roboto: 'Roboto, sans-serif',
};

export const sizeFont = {
  fontSize12: '12px',
  fontSize13: '13px',
  fontSize14: '14px',
  fontSize15: '15px',
  fontSize16: '16px',
  fontSize18: '18px',
  fontSize20: '20px',
  fontSize22: '22px',
  fontSize24: '24px',
  fontSize28: '28px',
  fontSize32: '32px',
  fontSize40: '40px',
};

export const fontWeight = {
  fw4x: '400',
  fw5x: '500',
  fw7x: '700',
};

export const skipBtn = {
  fontSize: sizeFont.fontSize14,
  fontWeight: '600',
};

// BORDERS

export const borders = {
  grayBorder: '0px -3px 8px 4px rgba(0, 0, 0, 0.1)',
};

// BOX SHADOWS

export const boxShadows = {
  gray: '0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
  grayHover: '0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3);',
  grayAccordion: '0px 1px 3px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3)',
};

// LINEAR GRADIENTS

export const linearGradient = {
  violet: 'linear-gradient(180deg, #e8e0f9 0%, rgba(232, 224, 249, 0) 100%)',
};

export const labelText = {
  fontFamily: fonts.body,
  fontSize: sizeFont.fontSize13,
  lineHeight: '16px',
  letterSpacing: '0.4px',
  color: colors.lightgray,
};

export const labelTextGray = {
  fontFamily: fonts.body,
  fontSize: sizeFont.fontSize13,
  lineHeight: '16px',
  letterSpacing: '0.4px',
  color: colors.lightgray,
  marginTop: '2px',
};

export const labelTextBlue = {
  fontFamily: fonts.body,
  fontSize: sizeFont.fontSize13,
  lineHeight: '16px',
  letterSpacing: '0.4px',
  color: colors.blue,
};

export const labelTextRed = {
  fontFamily: fonts.body,
  fontSize: sizeFont.fontSize13,
  lineHeight: '16px',
  letterSpacing: '0.4px',
  paddingLeft: '10px',
  color: colors.red,
  marginTop: '2px',
};

export const labelTextFocus = {
  marginTop: 0,
  fontFamily: fonts.body,
  fontSize: sizeFont.fontSize13,
  lineHeight: '16px',
  letterSpacing: '0.4px',
  color: colors.blue,
};

export const labelTextError = {
  marginTop: 0,
  fontFamily: fonts.body,
  fontSize: sizeFont.fontSize13,
  lineHeight: '16px',
  letterSpacing: '0.4px',
  color: colors.red,
};

export const eyebrow14 = {
  fontFamily: fonts.body,
  fontSize: sizeFont.fontSize14,
  lineHeight: '17px',
  color: colors.lightgray,
};

export const headline20 = {
  fontFamily: fonts.heading,
  fontSize: sizeFont.fontSize20,
  lineHeight: '30px',
  fontWeight: fontWeight.fw7x,
  fontStyle: 'normal',
  color: colors.textgray,
};

export const headline24 = {
  fontFamily: fonts.heading,
  fontSize: sizeFont.fontSize24,
  fontWeight: fontWeight.fw7x,
  color: colors.textgray,
};

export const headlineh3 = {
  fontFamily: fonts.heading,
  fontStyle: 'normal',
  fontWeight: fontWeight.fw7x,
  fontSize: sizeFont.fontSize28,
};

export const headline32 = {
  fontFamily: fonts.heading,
  fontSize: sizeFont.fontSize32,
  lineHeight: '32px',
  fontWeight: fontWeight.fw7x,
  color: colors.textgray,
};

export const body14 = {
  fontFamily: fonts.body,
  fontSize: sizeFont.fontSize14,
  lineHeight: '20px',
  color: colors.textgray,
};

export const body14Simple = {
  fontFamily: fonts.body,
  fontSize: sizeFont.fontSize14,
};

export const body15 = {
  fontFamily: fonts.body,
  fontSize: sizeFont.fontSize15,
  lineHeight: '22px',
  color: colors.textgray,
};

export const body14bold = {
  fontFamily: fonts.body,
  fontSize: sizeFont.fontSize14,
  lineHeight: '20px',
  fontWeight: fontWeight.fw7x,
  color: colors.textgray,
};

export const body16bold = {
  fontFamily: fonts.body,
  fontSize: sizeFont.fontSize16,
  lineHeight: '20px',
  fontWeight: fontWeight.fw7x,
  color: colors.textgray,
};

export const body22 = {
  fontFamily: fonts.bodySemiBold,
  fontSize: sizeFont.fontSize22,
  lineHeight: '17px',
};

export const labelBold = {
  fontFamily: fonts.body,
};

export const fontSize = {
  semiSmall: '14px',
};

export const fontError = {
  fontFamily: fonts.body,
  fontSize: sizeFont.fontSize12,
  lineHeight: '16px',
};

export const link = {
  fontFamily: fonts.body,
  fontSize: sizeFont.fontSize14,
  lineHeight: '20px',
  fontWeight: '700',
  color: colors.InfoBETA120,
  textDecorationLine: 'underline',
};

/* eslint-disable import/prefer-default-export */
export const CONST_BREADCRUMB = [
  {
    key: 'inicio',
    id: 'Inicio',
    label: 'Inicio',
    link: '/',
  },
  {
    key: 'administracion',
    id: 'Administracion',
    label: 'Administración',
    link: '/esquemasdefirmas',
  },
  // {
  //   key: 'abm',
  //   id: 'abm',
  //   label: 'ABM configuración de datos',
  //   link: '/menu/Administración/abm',
  // },
];

import { Box, Skeleton } from '@chakra-ui/react';

function SkeletonAccounts() {
  return (
    <>
      <Box data-testid='loadingSkeleton'>
        <Skeleton mt='4' height='1.5rem' />
        <Skeleton mt='4' height='1.5rem' />
        <Skeleton marginTop='20px' color='#E3E2E6' height='3.5rem' />
      </Box>
      <Box marginTop='20px' data-testid='loading'>
        <Skeleton color='#E3E2E6' height='3rem' />
        <Skeleton marginTop='10px' color='#E3E2E6' height='3rem' />
        <Skeleton marginTop='10px' color='#E3E2E6' height='3rem' />
        <Skeleton marginTop='10px' color='#E3E2E6' height='3rem' />
        <Skeleton marginTop='10px' color='#E3E2E6' height='3rem' />
        <Skeleton marginTop='10px' color='#E3E2E6' height='3rem' />
        <Skeleton marginTop='10px' color='#E3E2E6' height='3rem' />
        <Skeleton marginTop='10px' color='#E3E2E6' height='3rem' />
        <Skeleton marginTop='10px' color='#E3E2E6' height='3rem' />
        <Skeleton marginTop='10px' color='#E3E2E6' height='3rem' />
      </Box>
    </>
  );
}

export default SkeletonAccounts;

/* eslint-disable import/prefer-default-export */

export const GeneralText = {
  pageTitle: 'Esquemas de firmas',
};
export const HomePageText = {
  homeTitle: 'Esquemas de firmas',
  homeSubtitle: 'Todo lo que configures en esta sección debe coincidir con lo declarado en los poderes bancarios.',
  cardCreateTitle: 'Crear un nuevo esquema',
  cardCreateSubtitle: 'Cargá los esquemas de firmas que te permitirán autorizar transferencias en tu empresa.',
  cardEditTitle: 'Tus esquemas',
  cardEditSubtitle: 'Consultá tus esquemas ya creados y modificá las personas firmantes, cuentas y límites.',
};

export const AccountsPageText = {
  title: 'Seleccioná las cuentas disponibles para tus firmantes',
  subTitle: 'Mostramos las cuentas que tus firmantes tienen en común para operar. Podés configurar los permisos en la sección ',
  subTitleStrong: 'Operadores.',
};
export const SelectionSignatoriesText = {
  selectionSignatoriesTitle: 'Agregá firmantes',
  selectionSignatoriesSubtitle:
    'Para agregarlos, tus operadores deben tener permisos para firmar transferencias. Podés asignarlos en la sección ',
  selectionSignatoriesTooltip: 'Son las personas apoderadas que figuran en\n el esquema de firmas y pueden\n autorizar transferencias',
  selectionSignatoriesSubtitleBold: ' Operadores. ',
  selectionSignatoriesAlertTitle: 'El esquema debe coincidir con el que tienen los bancos',
  selectionSignatoriesAlertSubtitle: 'Cargalo respetando las personas firmantes, cuentas y montos que figuran en el poder bancario. ',
  selectionSignatoriesInputSearchPlaceholder: 'Buscá por operador',
};

export const EmptyText = {
  emptyTitle: 'Todavía no creaste un esquema de firmas',
  emptySubtitle: 'Creá tus esquemas respetando los datos que figuran en el poder bancario.',
  emptyButton: 'Crear esquema',
};

export const EmptyValidatePreConditionsText = {
  emptyTitle: 'Tus bancos no te permiten crear esquemas de firmas',
  emptySubtitle: 'Para hacerlo, comunicate con los bancos que tengas dados de alta.',
  emptyButton: 'Volver al inicio',
};

export const EmptysBanksAccountsCandidatesText = {
  emptyTitle: 'No tenés ninguna cuenta asignada para operar con todos tus firmantes ',
  emptySubtitle: (
    <>
      Configurá los permisos para operar desde la sección <strong>Cuentas</strong> o pedile a un administrador que lo haga.
    </>
  ),
  emptyButton: 'Ir a Cuentas',
};

export const ModalText = {
  headerText: (
    <p>
      ¿Querés descartar el <br /> progreso?
    </p>
  ),
  bodyText: (
    <p>
      Al salir de esta sección se borrará el <br /> progreso que hiciste.
    </p>
  ),
  buttonName1: 'Cancelar',
  buttonName2: 'Sí, descartar',
};

export const ModalTextError = {
  headerText: (
    <p>
      No podemos crear tu <br /> esquema
    </p>
  ),
  bodyText: (
    <p>
      Detectamos que hubo cambios en los datos <br />
      ingresados mientras completabas el proceso. <br />
      Volvé a crear el esquema de firmas.
    </p>
  ),
  buttonName1: 'Ir al inicio',
  buttonName2: 'Volver a Esquemas de firmas',
};

export const ModalTextCreateSchema = {
  headerText: (
    <p>
      Creaste un esquema de firmas <br />
      ilimitado con éxito
    </p>
  ),
  bodyText: (
    <p>
      Los bancos deberán aprobarlo para operar.
      <br />
      Si la administración de tu empresa es conjunta, un segundo <br />
      administrador tendrá que autorizarlo previamente
    </p>
  ),
  descriptions: 'Si elegís configurar los topes, continuarás en una ventana nueva.',
  buttonName1: 'Volver al inicio',
  buttonName2: 'Ir a Tus esquemas',
};

export const ModalAlertText = {
  headerText: <p>Si tu esquema tiene topes, necesitás configurarlos</p>,
  bodyText: (
    <p>
      Podés hacerlo desde <strong>Tus esquemas.</strong> Deben coincidir con el <br />
      poder bancario para que los bancos aprueben.
    </p>
  ),
};

export const footerText = {
  selectionAccountSingular: 'cuenta seleccionada',
  selectionAccountsPlural: 'cuentas seleccionadas ',
};
export const ButtonsName = {
  continue: 'Continuar',
  back: 'Volver',
  cancel: 'Cancelar',
  createSchema: 'Crear esquema',
};
export const ErrorContentText = {
  ErrorContentTitleText: 'No podemos mostrar la página',
  ErrorContentBodyText: 'Puede que el enlace haya cambiado o no exista más. Revisá la URL y volvé a intentar más tarde.',
  ErrorContentLinkText: 'Ir al Inicio',
};

export const ErrorServiceText = {
  ErrorContentTitleText: 'La información no está disponible',
  ErrorContentBodyText: 'Estamos trabajando para solucionarlo, volvé a intentar más tarde.',
  ErrorContentLinkText: 'Volver a Esquemas de firmas',
};

export const columnSignatoriesText = {
  columnOne: {
    title: 'FIRMA 1',
  },
  columnTwo: {
    title: 'FIRMA 2',
  },
  columnThree: {
    title: 'FIRMA 3',
  },
  buttonText: 'Agregar firmantes',
};

export const textTitleCards = {
  title: [
    {
      id: 1,
      parts: [
        { text: 'Para empezar, agregá firmantes a la ', bold: false },
        { text: 'Firma 1', bold: true },
      ],
    },
    {
      id: 2,
      parts: [
        { text: 'Solo 1 firmante ', bold: true },
        { text: 'tendrá que autorizar las transferencias ', bold: false },
      ],
    },
    {
      id: 3,
      parts: [
        { text: '2 firmantes ', bold: true },
        { text: 'tendrán que autorizar las transferencias: ', bold: false },
        { text: 'uno de la Firma 1', bold: true },
        { text: ' con ', bold: false },
        { text: 'uno de la Firma 2', bold: true },
      ],
    },
    {
      id: 4,
      parts: [
        { text: '3 firmantes ', bold: true },
        { text: 'tendrán que autorizar las transferencias: ', bold: false },
        { text: 'uno de la Firma 1', bold: true },
        { text: ' con ', bold: false },
        { text: 'uno de la Firma 2', bold: true },
        { text: ' más ', bold: false },
        { text: 'uno de la Firma 3', bold: true },
      ],
    },
  ],
};

export const steps = {
  stepOne: 'Paso 1',
  stepsTwo: 'Paso 2',
};

export const LoadingScreenText = {
  title: 'Estamos procesando tu información',
  SubTitle: 'Esto puede demorar unos instantes.',
};

/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-array-index-key */
import { Box, Button, Text } from '@chakra-ui/react';
// eslint-disable-next-line import/no-unresolved
import { OpenLink } from '@react-ib-mf/style-guide-ui';

import IEmptyStateScreen from '../../interfaces/IEmptyStateScreen';
import EMPTY_RESULTS_CONF from './config';
import { Container } from './styled';

function EmptyStateScreen({ hasButton = false, onRetry, page, variant, redirectionSib }: IEmptyStateScreen) {
  const noResultConfig = EMPTY_RESULTS_CONF[page][variant];
  const url = `${process.env.SIB_CUENTAS_BUSCAR}`;

  return (
    <Container>
      {noResultConfig.picture}
      <Text textStyle='h4Bold' className='empty-state-screen-title'>
        {noResultConfig.title}
      </Text>
      <Text textStyle='bodyMd' className='empty-state-screen-subtitle'>
        {noResultConfig.subtitle}
      </Text>
      {hasButton && (
        <Box className='empty-state-screen-button'>
          <Button variant='primary' onClick={onRetry} size='md'>
            {noResultConfig.buttonLabel}
          </Button>
        </Box>
      )}
      {redirectionSib && (
        <OpenLink url={url} openMode='newTab' reuseTab>
          <Box className='empty-state-screen-button'>
            <Button variant='primary-text' onClick={onRetry} size='md'>
              {noResultConfig.buttonLabel}
            </Button>
          </Box>
        </OpenLink>
      )}
    </Container>
  );
}
export default EmptyStateScreen;

import { Avatar, Box, Checkbox, Grid, Text, useDisclosure } from '@chakra-ui/react';
import { IconButtonComponent } from '@wow/button';
import { ErrorContentComponent, ErrorContentProps } from '@wow/error-content';
import { LoadingScreenComponent as LoadingScreen } from '@wow/loading-screen';
import { WizardComponent } from '@wow/wizard';
import { useContext, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { postAccountCandidate, postCreateSchema } from '../../../services/api';
import accountsMock from '../../__mocks__/accounts';
import Arrow from '../../assets/icons/arrow';
import imageBanco from '../../assets/icons/banco-logo-general.svg';
import SkeletonAccounts from '../../commons/SkeletonAccounts';
import EmptyStateScreen from '../../components/EmptyStateScreen';
import FilterTag from '../../components/filterTag';
import Footer from '../../components/footer';
import ModalCreateSchemaError from '../../components/modal/modalCreateSchemaError';
import ModalCreateSchemaSuccess from '../../components/modal/modalCreateSchemaSuccess';
import routesNames from '../../constants/routesNames';
import AppContext from '../../context/Provider';
import { MutationAccountCandidate } from '../../interfaces/IMutationAccountCandidate';
import { CreateSchema } from '../../interfaces/IMutationCreateSigners';
import { AccountsData, ResponseDataAccounts } from '../../interfaces/ResposeDataAccouts';
import {
  AccountsPageText,
  ButtonsName,
  ErrorServiceText,
  LoadingScreenText,
  ModalTextCreateSchema,
  ModalTextError,
  steps,
} from '../../text/GeneralText';
import finishWizard from '../../utils/finishWizard';
import handleModalRedirectHome from '../../utils/handleModalRedirectHome';
import handleModalRedirectPlataforma from '../../utils/handleModalRedirectPlataforma';
import WizardSteps from '../../utils/wizardSteps';

function Accounts() {
  const {
    accountsCandidateValues,
    setShowErrorContent,
    selectedItemsAccount,
    setSelectedItemsAccount,
    fetchData,
    initialized,
    setInitialized,
    currentStep,
    setCurrentStep,
    setItemsColumnOne,
    setItemsColumnTwo,
    setItemsColumnThree,
    setFilteredData,
    modalWelcome,
    itemsColumnOne,
    itemsColumnTwo,
    itemsColumnThree,
  } = useContext(AppContext);

  const navigate = useNavigate();

  const { isOpen: isOpenModalError, onOpen: onOpenModalError, onClose: onCloseModalError } = useDisclosure();
  const { isOpen: isOpenModalSuccess, onOpen: onOpenModalSuccess, onClose: onCloseModalSuccess } = useDisclosure();

  const [hasError, setHasError] = useState(false);
  const [hasSuccess, setHasSuccess] = useState(true);
  const {
    data,
    mutate: mutationAccountCandidate,
    error: mutationAccountCandidateError,
    isLoading: mutationAccountCandidateIsLoading,
  } = useMutation<ResponseDataAccounts, Error, MutationAccountCandidate>(async (): Promise<ResponseDataAccounts> => {
    const result = await postAccountCandidate(accountsCandidateValues);
    return result;
  });

  const [accounts, setAccounts] = useState(accountsMock);
  useEffect(() => {
    if (modalWelcome === true) {
      setAccounts(accountsMock);
      setSelectedItemsAccount([accounts.data[0], accounts.data[1], accounts.data[2], accounts.data[3]]);
    } else {
      setAccounts(data);
    }
  }, [data, initialized, currentStep]);

  const previusStep = () => {
    navigate(`/${routesNames.SelectionSignatories}`);
  };

  const handleGoBackHome = () => {
    setShowErrorContent(false);
    return navigate(`/${routesNames.Home}`);
  };

  const errorContentProps: ErrorContentProps = {
    titleText: ErrorServiceText.ErrorContentTitleText,
    bodyText: ErrorServiceText.ErrorContentBodyText,
    linkText: ErrorServiceText.ErrorContentLinkText,
    isRouter: true,
    accessError: 'content',
    callbackAction: () => handleGoBackHome(),
  };

  const [filteredBanks, setFilteredBanks] = useState<AccountsData[]>([]);

  const handleFilterChange = (selectedBanks: Set<string>) => {
    const filtered = data?.data.filter(bank => selectedBanks.has(bank.bankShortName)) || [];
    setFilteredBanks(filtered);
  };

  const handleCheckboxChange = bank => {
    const isAlreadySelected = selectedItemsAccount.some(item => item.cbu === bank.cbu);
    if (isAlreadySelected) {
      setSelectedItemsAccount(prev => prev.filter(item => item.cbu !== bank.cbu));
    } else {
      setSelectedItemsAccount(prev => [...prev, { bankBCRAId: bank.bankBCRAId, cbu: bank.cbu }]);
    }
  };

  const handleMainCheckboxChange = (e, banks) => {
    if (e.target.checked) {
      setSelectedItemsAccount(banks.map(bank => ({ bankBCRAId: bank.bankBCRAId, cbu: bank.cbu })));
    } else {
      setSelectedItemsAccount([]);
    }
  };

  const handleGoBack = () => {
    setSelectedItemsAccount([]);
    navigate(`/${routesNames.SelectionSignatories}`);
  };

  const handleSubmit = schema => {
    mutateCreateSchema(schema);
  };

  useEffect(() => {
    mutationAccountCandidate(accountsCandidateValues);
  }, []);

  const {
    mutate: mutateCreateSchema,
    error: mutationCreateSchemaError,
    isSuccess: mutationCreateSchemaIsSuccess,
    isLoading: mutationCreateSchemaIsLoading,
  } = useMutation<void, Error, CreateSchema>(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    async (createSchema): Promise<void> => {
      const result = await postCreateSchema(createSchema);
      return result;
    },
  );

  useEffect(() => {
    if (mutationCreateSchemaError && !hasError) {
      setHasError(true);
      openModalError();
    }
  }, [mutationCreateSchemaError, hasError, onOpenModalError]);

  useEffect(() => {
    if (mutationCreateSchemaIsSuccess && hasSuccess) {
      setHasSuccess(false);
      openModalSuccess();
    }
  }, [mutationCreateSchemaIsSuccess, hasSuccess, onOpenModalSuccess]);

  if (mutationAccountCandidateIsLoading) {
    return <SkeletonAccounts />;
  }

  if ((modalWelcome === false && mutationAccountCandidateError) || (currentStep !== 5 && modalWelcome === true)) {
    setShowErrorContent(true);
    return <ErrorContentComponent ErrorContentProps={errorContentProps} />;
  }

  if (modalWelcome === false && data?.status === 204)
    return (
      <Box pt='3rem'>
        <EmptyStateScreen page='ACCOUNT' variant='ACCESS_NO_DATA' redirectionSib />
      </Box>
    );

  const openModalError = () => {
    onOpenModalError();
  };

  const openModalSuccess = () => {
    onOpenModalSuccess();
  };

  if (mutationCreateSchemaIsLoading) {
    return <LoadingScreen title={LoadingScreenText.title} description={LoadingScreenText.SubTitle} />;
  }

  const createSchemaSubmit = {
    signerList1: itemsColumnOne.map(signers => {
      return {
        documentNumber: signers.documentNumber,
        countryCode: signers.countryCode,
      };
    }),
    signerList2: itemsColumnTwo.map(signers => {
      return {
        documentNumber: signers.documentNumber,
        countryCode: signers.countryCode,
      };
    }),
    signerList3: itemsColumnThree.map(signers => {
      return {
        documentNumber: signers.documentNumber,
        countryCode: signers.countryCode,
      };
    }),
    accounts: selectedItemsAccount.map(account => {
      return {
        cbu: account.cbu,
        topeMontoTransaccion: 0,
        topeMontoDia: 0,
      };
    }),

    topeDia: 0, // SIEMPRE 0
    currencyCode: '', // SIEMPRE VACIO
    bcraCod: '', // SIEMPRE VACIO
  };

  return (
    <Box>
      <>
        <Box id='accounts' p='5px'>
          <Box>
            <Text textStyle='h2Bold' mt='1rem'>
              {AccountsPageText.title}
            </Text>
          </Box>

          <Box>
            <Box>
              <Text textStyle='bodyMd' mt='0.50rem'>
                {AccountsPageText.subTitle}
                <Text as='span' textStyle='bodyMdSemi'>
                  {AccountsPageText.subTitleStrong}
                </Text>
              </Text>
              <Box>
                <FilterTag banks={data?.data || []} onChange={handleFilterChange} />
              </Box>
            </Box>
            <Box w='full' mt='2rem'>
              <Grid templateColumns='0.5fr 3fr 3fr 3fr 1fr 1fr' bg='#EFF0FA' fontWeight='bold' gap={4} px={4} py={2} mb='0.5rem'>
                <Checkbox
                  aria-checked={
                    selectedItemsAccount.length === (filteredBanks.length > 0 ? filteredBanks.length : data?.data.length) ? 'true' : 'false'
                  }
                  type='checkbox'
                  role='checkbox'
                  data-testid='all-checkbox-selected'
                  isChecked={selectedItemsAccount.length === (filteredBanks.length > 0 ? filteredBanks.length : data?.data.length)}
                  onChange={e => handleMainCheckboxChange(e, filteredBanks.length > 0 ? filteredBanks : data?.data)}
                  isIndeterminate={
                    !(
                      selectedItemsAccount.length === 0 ||
                      selectedItemsAccount.length === (filteredBanks.length > 0 ? filteredBanks.length : data?.data.length)
                    )
                  }
                />

                <Box display='flex' alignItems='center' gap={2}>
                  <Text textStyle='titleSmBold'>Cuenta</Text>
                  <IconButtonComponent
                    aria-label='Ordenar Cuenta'
                    icon={<Arrow />}
                    size='i-sm'
                    variant='icon-text' //
                    // onClick={() => handleSort('accountNumber')}
                  />
                </Box>
                <Box display='flex' alignItems='center' gap={2}>
                  <Text textStyle='titleSmBold'>CBU</Text>
                  <IconButtonComponent
                    aria-label='Ordenar CBU'
                    icon={<Arrow />}
                    size='i-sm'
                    variant='icon-text' //
                    // onClick={() => handleSort('cbu')}
                  />
                </Box>
                <Box display='flex' alignItems='center' gap={2}>
                  <Text textStyle='titleSmBold'>Razón social</Text>
                  <IconButtonComponent
                    aria-label='Ordenar Razón Social'
                    icon={<Arrow />}
                    size='i-sm'
                    variant='icon-text' //
                    // onClick={() => handleSort('accountDescription')}
                  />
                </Box>
                <Box display='flex' alignItems='center' gap={2}>
                  <Text textStyle='titleSmBold'>Tipo</Text>
                  <IconButtonComponent
                    aria-label='Ordenar Tipo'
                    icon={<Arrow />}
                    size='i-sm'
                    variant='icon-text' //
                    // onClick={() => handleSort('accountType')}
                  />
                </Box>
                <Box display='flex' alignItems='center' gap={2}>
                  <Text textStyle='titleSmBold'>Moneda</Text>
                  <IconButtonComponent
                    aria-label='Ordenar Moneda'
                    icon={<Arrow />}
                    size='i-sm'
                    variant='icon-text'
                    // onClick={() => handleSort('currencyCode')}
                  />
                </Box>
              </Grid>
              <Box overflow='auto'>
                {(filteredBanks.length > 0 ? filteredBanks : accounts?.data)?.map(item => (
                  <Grid
                    key={item.accountNumber}
                    templateColumns='0.5fr 3fr 3fr 3fr 1fr 1fr'
                    gap={4}
                    px={4}
                    py={2}
                    alignItems='center'
                    background={selectedItemsAccount.some(selectedItem => selectedItem.cbu === item.cbu) ? '#E8F1FC' : null}
                  >
                    <Checkbox
                      aria-checked={selectedItemsAccount.some(selectedItem => selectedItem.cbu === item.cbu) ? 'true' : 'false'}
                      type='checkbox'
                      role='checkbox'
                      data-testid={`checkbox-${item.cbu}`}
                      isChecked={selectedItemsAccount.some(selectedItem => selectedItem.cbu === item.cbu)}
                      onChange={() => handleCheckboxChange(item)}
                    />
                    <Box display='flex' alignItems='center' gap={2}>
                      <Avatar
                        backgroundColor='#2172DC'
                        size='sm'
                        color='white'
                        src={item.bankBCRAId ? `${process.env.URL_BANK_LOGOS}logo${item.bankBCRAId}.png` : imageBanco}
                        name={`${item.bankShortName}`}
                      />
                      <Box>
                        <Text textStyle='labelSmBold'>{item.bankShortName}</Text>
                        <Text textStyle='labelSm'>{item.accountNumber}</Text>
                      </Box>
                    </Box>
                    <Text textStyle='bodySm'>{item.cbu}</Text>
                    <Text textStyle='bodySm'>{item.accountDescription}</Text>
                    <Text textStyle='bodySm'>{item.accountType}</Text>
                    <Text textStyle='bodySm'>{item.currencyCode}</Text>
                  </Grid>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
        <Footer
          fetchData={() => fetchData()}
          loadingText={ButtonsName.continue}
          steps={steps.stepsTwo}
          buttonCancel
          isDisabled={selectedItemsAccount.length === 0}
          dinamicText={selectedItemsAccount.length !== 0}
          goBack
          handleGoBack={handleGoBack}
          handleSubmit={() => handleSubmit(createSchemaSubmit)}
          buttonCreateSchema
        />
      </>

      <WizardComponent
        id='wizard'
        steps={WizardSteps}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        initialized={initialized}
        setInitialized={setInitialized}
        width={296}
        height={260}
        offset={20}
        initialLabel='Comenzar'
        finishLabel='Finalizar'
        onFinish={() =>
          finishWizard(
            setSelectedItemsAccount,
            setFilteredData,
            setItemsColumnOne,
            setItemsColumnTwo,
            setItemsColumnThree,
            navigate,
            routesNames,
          )
        }
        onPrevious={() => previusStep()}
        onNext={() => null}
        hasWelcome={false}
      />

      <ModalCreateSchemaError
        bodyText={ModalTextError.bodyText}
        buttonName1={ModalTextError.buttonName1}
        buttonName2={ModalTextError.buttonName2}
        onCancel={() => handleModalRedirectPlataforma(setHasError, onCloseModalError, onCloseModalSuccess, navigate)}
        onConfirm={() =>
          handleModalRedirectHome(setHasError, onCloseModalError, onCloseModalSuccess, navigate, setSelectedItemsAccount, fetchData)
        }
        isOpen={isOpenModalError}
        onClose={() => handleModalRedirectPlataforma(setHasError, onCloseModalError, onCloseModalSuccess, navigate)}
        state='error'
      >
        <Text textAlign='center' textStyle='h3'>
          {ModalTextError.headerText}
        </Text>
      </ModalCreateSchemaError>

      <ModalCreateSchemaSuccess
        bodyText={ModalTextCreateSchema.bodyText}
        buttonName1={ModalTextCreateSchema.buttonName1}
        buttonName2={ModalTextCreateSchema.buttonName2}
        onCancel={() => handleModalRedirectPlataforma(setHasError, onCloseModalError, onCloseModalSuccess, navigate)}
        onConfirm={() =>
          handleModalRedirectHome(setHasError, onCloseModalError, onCloseModalSuccess, navigate, setSelectedItemsAccount, fetchData)
        }
        isOpen={isOpenModalSuccess}
        onClose={() => handleModalRedirectPlataforma(setHasError, onCloseModalError, onCloseModalSuccess, navigate)}
        state='success'
      >
        <Text textAlign='center' textStyle='h3'>
          {ModalTextCreateSchema.headerText}
        </Text>
      </ModalCreateSchemaSuccess>
    </Box>
  );
}

export default Accounts;

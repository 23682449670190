// eslint-disable-next-line import/no-unresolved
import { getToken } from '@react-ib-mf/style-guide-ui';
import axios from 'axios';
import { MutationSignersValidate } from '../src/interfaces/IMutationSignersValidate';
import { MutationAccountCandidate } from '../src/interfaces/IMutationAccountCandidate';
import { ResponseDataAccounts } from '../src/interfaces/ResposeDataAccouts';
import { PermissionsResponse } from '../src/interfaces/Permissions';
import { CreateSchema } from '../src/interfaces/IMutationCreateSigners';

const newAxios = axios.create({
  baseURL: process.env.EDF_BASE_URL,
  headers: { Authorization: `Bearer ${getToken()}`, user_key: `${process.env.API_USER_KEY}` },
});

export const getSignatureSchemes = () => newAxios.get('/v1/signatures-schema');
export const getOperatorsSignature = () => newAxios.get('/v1/operators-signature');
export const getValidatePreConditions = () => newAxios.get('/v1/validate-pre-conditions');
export const getPermissions = () => newAxios.get<PermissionsResponse>(`v1/permission/operator`);
export const getWhiteList = () => newAxios.get('/v1/white-list');

export const postSignersValidate = async (
  signerList: MutationSignersValidate[]
) => {

  const res = await newAxios.request({
    url: `/v1/validate`,
    method: 'POST',
    data: signerList,
  });

  return res.data;
};

export const postAccountCandidate = async (
  candidateSigners: MutationAccountCandidate
): Promise<ResponseDataAccounts | { status: number }> => {
  const res = await newAxios.request({
    url: `/v1/banks-accounts-candidates`,
    method: 'POST',
    data: candidateSigners,
  });

  if (res.status === 204) {
    return { status: res.status };
  }

  return {
    data: res.data?.data || [],
    success: res.data?.success ?? true,
    status: res.status,
  };
};

export const postCreateSchema = async (
  schemaSigners: CreateSchema

) => {
  const res = await newAxios.request({
    url: `/v1/signature-schema/create`,
    method: 'POST',
    data: schemaSigners,
  });

  return res.data;
};

function Arrow() {
  return (
    <svg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.8218 3.53033C13.5289 3.82322 13.0541 3.82322 12.7612 3.53033L11.7915 2.56066L11.7915 14.25C11.7915 14.6642 11.4557 15 11.0415 15C10.6273 15 10.2915 14.6642 10.2915 14.25L10.2915 2.56066L9.32183 3.53033C9.02894 3.82322 8.55407 3.82322 8.26117 3.53033C7.96828 3.23744 7.96828 2.76256 8.26117 2.46967L10.5112 0.21967C10.8041 -0.073223 11.2789 -0.073223 11.5718 0.21967L13.8218 2.46967C14.1147 2.76256 14.1147 3.23744 13.8218 3.53033ZM6.69683 11.4697C6.98973 11.7626 6.98973 12.2374 6.69683 12.5303L4.44683 14.7803C4.15394 15.0732 3.67907 15.0732 3.38617 14.7803L1.13617 12.5303C0.843281 12.2374 0.843281 11.7626 1.13617 11.4697C1.42907 11.1768 1.90394 11.1768 2.19683 11.4697L3.1665 12.4393L3.1665 0.75C3.1665 0.335786 3.50229 -1.81059e-08 3.9165 0C4.33072 1.81059e-08 4.6665 0.335786 4.6665 0.75L4.6665 12.4393L5.63617 11.4697C5.92907 11.1768 6.40394 11.1768 6.69683 11.4697Z'
        fill='#565E71'
      />
    </svg>
  );
}

export default Arrow;

function Bank() {
  return (
    <svg width='90' height='84' viewBox='0 0 90 84' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M5 25H75V63H5V25Z' fill='#94BCF0' />
      <path d='M74 26V62H6V26H74ZM76 24H4V64H76V24Z' fill='#2172DC' />
      <path d='M1 69H79V75H1V69Z' fill='#E8F1FC' />
      <path d='M78 70V74H2V70H78ZM80 68H0V76H80V68Z' fill='#2172DC' />
      <path d='M3 63H77V69H3V63Z' fill='#E8F1FC' />
      <path d='M76 64V68H4V64H76ZM78 62H2V70H78V62Z' fill='#2172DC' />
      <path d='M3 25V20.664L40 5.086L77 20.664V25H3Z' fill='#BBD5F6' />
      <path d='M40 6.17L76 21.328V24H4V21.328L40 6.17ZM40 4L2 20V26H78V20L40 4Z' fill='#2172DC' />
      <path
        d='M66 26H62V30H54V26H50V30H42V26H38V30H30V26H26V30H18V26H14V30H6V32H14V62H18V32H26V62H30V32H38V62H42V32H50V62H54V32H62V62H66V32H74V30H66V26Z'
        fill='#2172DC'
      />
      <circle cx='72' cy='66' r='17.5' fill='#D9D9D9' stroke='#2172DC' />
      <g clipPath='url(#clip0_8769_40280)'>
        <path
          d='M72 82.6504C62.8191 82.6504 55.35 75.1813 55.35 66.0004C55.35 56.8195 62.8191 49.3504 72 49.3504C81.1809 49.3504 88.65 56.8195 88.65 66.0004C88.65 75.1813 81.1809 82.6504 72 82.6504Z'
          fill='#94BCF0'
        />
        <path
          d='M72 49.8004C80.9325 49.8004 88.2 57.0679 88.2 66.0004C88.2 74.9329 80.9325 82.2004 72 82.2004C63.0675 82.2004 55.8 74.9329 55.8 66.0004C55.8 57.0679 63.0675 49.8004 72 49.8004ZM72 48.9004C62.5563 48.9004 54.9 56.5567 54.9 66.0004C54.9 75.4441 62.5563 83.1004 72 83.1004C81.4437 83.1004 89.1 75.4441 89.1 66.0004C89.1 56.5567 81.4437 48.9004 72 48.9004Z'
          fill='#2172DC'
        />
        <path
          d='M65.3178 70.7732C64.9664 71.1247 64.9663 71.6945 65.3178 72.046L65.9542 72.6824C66.3057 73.0339 66.8755 73.0339 67.227 72.6824L72 67.9096L76.7731 72.6825C77.1246 73.0339 77.6944 73.0339 78.0459 72.6825L78.6823 72.046C79.0338 71.6946 79.0337 71.1247 78.6823 70.7733L73.9093 66.0004L78.6823 61.2275C79.0338 60.876 79.0338 60.3062 78.6823 59.9547L78.046 59.3183C77.6945 58.9668 77.1246 58.9668 76.7732 59.3183L72 64.0913L67.2269 59.3183C66.8755 58.9669 66.3056 58.9669 65.9541 59.3183L65.3178 59.9548C64.9663 60.3062 64.9663 60.8761 65.3178 61.2275L70.0908 66.0004L65.3178 70.7732Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_8769_40280'>
          <rect width='36' height='36' fill='white' transform='translate(54 48)' />
        </clipPath>
      </defs>
    </svg>
  );
}
export default Bank;

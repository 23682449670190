import { Box, Button, CloseButton, Flex, Text } from '@chakra-ui/react';

import { Operator } from '../../interfaces/Operator';
import { columnSignatoriesText } from '../../text/GeneralText';
import { StylesType } from '../../views/SelectionSignatories';
import { CardSectionHorizontal, CenteredButton, SignatorsCard, StyledCard, TextContainer } from './styled';

interface ColumnSelectedSignatoriesProps {
  titleText: string;
  data: Operator[];
  addSigners: () => void;
  removeSigners: (operator: Operator) => void;
  checkbox: any;
  deleteButton?: boolean;
  // Testing
  dataTestIdAddSignerButton?: string;
  dataTestIdRemoveSignerButton?: string;
  dataTestIdColumn?: string;
  styles?: StylesType;
}

function ColumnSelectedSignatories({
  titleText,
  data,
  addSigners,
  removeSigners,
  dataTestIdAddSignerButton,
  dataTestIdRemoveSignerButton,
  dataTestIdColumn,
  checkbox,
  styles,
  deleteButton,
}: ColumnSelectedSignatoriesProps) {
  const { borderDottedBold, borderDotted, borderSolid, borderDottedBlue, backgroundBlue, buttonAddSigners, colorDarkTitle } = styles;
  return (
    <Box data-testid={dataTestIdColumn}>
      <StyledCard
        borderDottedBold={borderDottedBold}
        borderDotted={borderDotted}
        borderSolid={borderSolid}
        borderDottedBlue={borderDottedBlue}
        backgroundBlue={backgroundBlue}
      >
        <CardSectionHorizontal>
          <TextContainer colorDarkTitle={colorDarkTitle}>
            <Text textStyle='overlineLg'>{titleText}</Text>
          </TextContainer>
        </CardSectionHorizontal>
        {buttonAddSigners ? (
          <CenteredButton>
            <Button
              disabled={!(checkbox.length > 0)}
              data-testid={dataTestIdAddSignerButton}
              variant='primary-outline'
              onClick={addSigners}
              style={{ width: '100%' }}
            >
              {columnSignatoriesText.buttonText}
            </Button>
          </CenteredButton>
        ) : null}
        <Box width='100%'>
          {data?.map(signator => {
            return (
              <SignatorsCard key={signator.operatorId} styleWithCheckbox={checkbox.length > 0}>
                <Flex className='container'>
                  <Text>{`${signator.firstName} ${signator.lastName}`}</Text>
                  {checkbox.length === 0 && deleteButton ? (
                    <CloseButton
                      data-testid={dataTestIdRemoveSignerButton}
                      onClick={() => removeSigners(signator)}
                      size='sm'
                      variant='light'
                    />
                  ) : null}
                </Flex>
              </SignatorsCard>
            );
          })}
        </Box>
      </StyledCard>
    </Box>
  );
}
export default ColumnSelectedSignatories;

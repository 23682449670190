import React from 'react';

interface SearchIconProps {
  width?: number | string;
  height?: number | string;
  fill?: string;
}

// eslint-disable-next-line react/function-component-definition
const SearchIcon: React.FC<SearchIconProps> = ({ width = 24, height = 24, fill = '#474E5E' }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' fill={fill}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.6179 18.0317C15.0782 19.2635 13.1251 20 11 20C6.02944 20 2 15.9706 2 11C2 6.02944 6.02944 2 11 2C15.9706 2 20 6.02944 20 11C20 13.1249 19.2636 15.0778 18.0321 16.6175L21.7075 20.2929C22.098 20.6834 22.098 21.3166 21.7075 21.7071C21.317 22.0977 20.6838 22.0977 20.2933 21.7071L16.6179 18.0317ZM4 11C4 7.13401 7.13401 4 11 4C14.866 4 18 7.13401 18 11C18 12.8866 17.2537 14.5988 16.0402 15.8577C16.0065 15.8836 15.9741 15.9121 15.9433 15.9429C15.9124 15.9738 15.884 16.0062 15.858 16.0399C14.5991 17.2535 12.8867 18 11 18C7.13401 18 4 14.866 4 11Z'
      />
    </svg>
  );
};

export default SearchIcon;

import defaultStyles from './defaultStyles';

const stylesPresets = {
  nothingSelectedEmptyColumns: {
    columnOne: { ...defaultStyles, borderDottedBold: true, colorDarkTitle: true },
    columnTwo: { ...defaultStyles, borderDotted: true },
    columnThree: { ...defaultStyles, borderDotted: true },
  },
  nothingSelectedOneColumn: {
    columnOne: { ...defaultStyles, borderSolid: true, colorDarkTitle: true },
    columnTwo: { ...defaultStyles, borderDottedBold: true, colorDarkTitle: true },
    columnThree: { ...defaultStyles, borderDotted: true },
  },
  nothingSelectedTwoColumns: {
    columnOne: { ...defaultStyles, borderSolid: true, colorDarkTitle: true },
    columnTwo: { ...defaultStyles, borderSolid: true, colorDarkTitle: true },
    columnThree: { ...defaultStyles, borderDottedBold: true, colorDarkTitle: true },
  },
  nothingSelectedThreeColumns: {
    columnOne: { ...defaultStyles, borderSolid: true, colorDarkTitle: true },
    columnTwo: { ...defaultStyles, borderSolid: true, colorDarkTitle: true },
    columnThree: { ...defaultStyles, borderSolid: true, colorDarkTitle: true },
  },
  selectedWithEmptyColumns: {
    columnOne: { ...defaultStyles, borderDottedBlue: true, backgroundBlue: true, buttonAddSigners: true, colorDarkTitle: true },
    columnTwo: { ...defaultStyles, borderDotted: true },
    columnThree: { ...defaultStyles, borderDotted: true },
  },
  selectedWithOneColumn: {
    columnOne: { ...defaultStyles, borderDottedBlue: true, backgroundBlue: true, buttonAddSigners: true, colorDarkTitle: true },
    columnTwo: { ...defaultStyles, borderDottedBlue: true, backgroundBlue: true, buttonAddSigners: true, colorDarkTitle: true },
    columnThree: { ...defaultStyles, borderDotted: true },
  },
  selectedWithTwoColumns: {
    columnOne: { ...defaultStyles, borderDottedBlue: true, backgroundBlue: true, buttonAddSigners: true, colorDarkTitle: true },
    columnTwo: { ...defaultStyles, borderDottedBlue: true, backgroundBlue: true, buttonAddSigners: true, colorDarkTitle: true },
    columnThree: { ...defaultStyles, borderDottedBlue: true, backgroundBlue: true, buttonAddSigners: true, colorDarkTitle: true },
  },
  selectedWithThreeColumns: {
    columnOne: { ...defaultStyles, borderDottedBlue: true, backgroundBlue: true, buttonAddSigners: true, colorDarkTitle: true },
    columnTwo: { ...defaultStyles, borderDottedBlue: true, backgroundBlue: true, buttonAddSigners: true, colorDarkTitle: true },
    columnThree: { ...defaultStyles, borderDottedBlue: true, backgroundBlue: true, buttonAddSigners: true, colorDarkTitle: true },
  },
};
export default stylesPresets;

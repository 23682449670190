import { Box, Button, Flex, Text, useDisclosure } from '@chakra-ui/react';
import { useContext } from 'react';
import { ChevronLeft } from 'react-feather';
import { Link, useNavigate } from 'react-router-dom';

import AppContext from '../../context/Provider';
import { ButtonsName, footerText, ModalText } from '../../text/GeneralText';
import handleModal from '../../utils/handleModal';
import ModalState from '../modal';
import Panel from './styles';

interface FetchDataAndSubmit {
  fetchData?: () => void;
  handleSubmit?: () => void;
  isLoading?: boolean;
  loadingText?: string;
  steps?: string;
  buttonCancel?: boolean;
  isDisabled?: boolean;
  dinamicText?: boolean;
  goBack?: boolean;
  handleGoBack?: () => void;
  buttonCreateSchema?: boolean;
}

function Footer({
  fetchData,
  handleSubmit,
  isLoading,
  loadingText,
  steps,
  buttonCancel,
  isDisabled,
  dinamicText,
  goBack,
  handleGoBack,
  buttonCreateSchema,
}: FetchDataAndSubmit) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { selectedItemsAccount, setSelectedItemsAccount } = useContext(AppContext);
  const navigate = useNavigate();
  const { currentStep } = useContext(AppContext);

  const openModal = () => {
    onOpen();
  };

  const continueTo = '';
  const cancel = '';

  return (
    <>
      <Panel>
        <Box className={currentStep === 4 ? 'wizardButtons' : 'footer-container'} data-testid='footer'>
          <Flex justifyContent='space-between'>
            <Flex marginLeft='5rem' alignItems='center'>
              <Button onClick={goBack ? handleGoBack : openModal} size='md' leftIcon={<ChevronLeft />} variant='primary-text'>
                {ButtonsName.back}
              </Button>
              {dinamicText && (
                <Text textStyle='labelLgBold'>{`${selectedItemsAccount.length} ${
                  selectedItemsAccount.length === 1 ? footerText.selectionAccountSingular : footerText.selectionAccountsPlural
                }`}</Text>
              )}
            </Flex>
            <Flex className={currentStep === 4 ? 'boxButtons' : ''} id='boxButtons' marginRight='6rem'>
              <Text className='steps'>{steps}</Text>
              {buttonCancel ? (
                <>
                  <Box marginRight='1rem'>
                    <Link to={cancel}>
                      <Button onClick={openModal} size='md' variant='primary-outline'>
                        {ButtonsName.cancel}
                      </Button>
                    </Link>
                  </Box>
                  <Box>
                    <Link to={continueTo}>
                      <Button
                        onClick={handleSubmit}
                        size='md'
                        variant='primary'
                        isDisabled={isDisabled}
                        isLoading={isLoading}
                        loadingText={loadingText}
                      >
                        {buttonCreateSchema ? ButtonsName.createSchema : ButtonsName.continue}
                      </Button>
                    </Link>
                  </Box>
                </>
              ) : (
                <Link to={continueTo}>
                  <Button
                    onClick={handleSubmit}
                    size='md'
                    variant='primary'
                    isDisabled={isDisabled}
                    isLoading={isLoading}
                    loadingText={loadingText}
                  >
                    {ButtonsName.continue}
                  </Button>
                </Link>
              )}
            </Flex>
          </Flex>
        </Box>
      </Panel>
      <ModalState
        bodyText={ModalText.bodyText}
        buttonName1={ModalText.buttonName1}
        buttonName2={ModalText.buttonName2}
        onCancel={onClose}
        onConfirm={() => handleModal(onClose, navigate, setSelectedItemsAccount, fetchData)}
        isOpen={isOpen}
        onClose={onClose}
      >
        <Text textAlign='center' textStyle='h3'>
          {ModalText.headerText}
        </Text>
      </ModalState>
    </>
  );
}

export default Footer;

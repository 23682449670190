function Rocket() {
  return (
    <svg width='90' height='90' viewBox='0 0 90 90' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.98084 84.2603C8.48709 78.7568 10.0936 67.4663 13.1468 64.3275C14.0584 63.3768 15.1527 62.6202 16.3642 62.1032C17.5756 61.5863 18.879 61.3196 20.1961 61.3193C22.8578 61.3193 25.3621 62.388 27.2476 64.3275C31.1603 68.3527 31.1603 74.9002 27.2476 78.9255C24.2101 82.053 13.3246 83.7248 7.98084 84.2603Z'
        fill='#AAC7FF'
      />
      <path
        d='M20.1983 62.4443C22.5541 62.4443 24.7703 63.3915 26.4421 65.1105C29.9341 68.7038 29.9341 74.5492 26.4421 78.1403C24.0728 80.577 15.5228 82.2735 9.23409 82.9958C9.91809 76.4843 11.5606 67.5742 13.9568 65.1105C14.7635 64.268 15.7323 63.5974 16.805 63.1392C17.8776 62.681 19.0319 62.4446 20.1983 62.4443ZM20.1983 60.1943C18.7309 60.1957 17.2789 60.493 15.9289 61.0682C14.579 61.6435 13.3588 62.4849 12.3413 63.5422C8.00109 68.0085 6.75009 85.5 6.75009 85.5C6.75009 85.5 23.7151 84.1747 28.0553 79.7107C32.3956 75.2467 32.3956 68.0085 28.0553 63.5445C27.0381 62.4866 25.8181 61.6447 24.4681 61.0691C23.1181 60.4934 21.6659 60.1959 20.1983 60.1943Z'
        fill='#2172DC'
      />
      <path
        d='M22.2458 74.9295C20.7991 76.4167 15.2438 76.7812 15.2438 76.7812C15.2438 76.7812 15.5611 71.028 17.0078 69.5407C18.4546 68.0535 20.7991 68.0535 22.2458 69.5407C23.6926 71.028 23.6903 73.4422 22.2458 74.9295Z'
        fill='white'
      />
      <path
        d='M30.1028 80.3115C29.4188 80.3115 28.7776 80.0347 28.2916 79.533L12.5753 63.3645C12.0829 62.8509 11.808 62.1669 11.808 61.4554C11.808 60.7438 12.0829 60.0598 12.5753 59.5462L22.2458 49.599L41.6296 69.5385L31.9163 79.5307C31.6825 79.7767 31.4012 79.9727 31.0896 80.1068C30.7779 80.241 30.4422 80.3107 30.1028 80.3115Z'
        fill='#AAC7FF'
      />
      <path
        d='M22.2458 51.2145L40.0591 69.543L31.1063 78.7523C30.8341 79.0312 30.4763 79.1865 30.1006 79.1865C29.7248 79.1865 29.3671 79.0312 29.0948 78.7523L13.3808 62.586C13.0948 62.2808 12.9357 61.8782 12.9357 61.4599C12.9357 61.0416 13.0948 60.639 13.3808 60.3338L22.2458 51.2145ZM22.2458 47.9857L11.7698 58.7633C11.0737 59.4875 10.6849 60.4531 10.6849 61.4576C10.6849 62.4622 11.0737 63.4278 11.7698 64.152L27.4838 80.3182C27.8231 80.6705 28.2298 80.9507 28.6798 81.1421C29.1298 81.3334 29.6138 81.4321 30.1028 81.4321C30.5919 81.4321 31.0758 81.3334 31.5258 81.1421C31.9759 80.9507 32.3826 80.6705 32.7218 80.3182L43.1978 69.5407L22.2458 47.9857Z'
        fill='#2172DC'
      />
      <path
        d='M41.5103 76.9702L15.0661 49.7633L5.77809 44.9842L15.0121 35.4847C15.7291 34.7374 16.6235 34.1835 17.6121 33.8747C18.6007 33.5659 19.6514 33.5121 20.6663 33.7185L29.5336 35.5455L55.2511 62.001L57.0286 71.145C57.2363 72.1924 57.1876 73.2745 56.8867 74.299C56.5858 75.3235 56.0417 76.26 55.3006 77.0287L46.1273 86.4675L41.5103 76.9702Z'
        fill='#AAC7FF'
      />
      <path
        d='M19.4423 34.7197C19.7753 34.7197 20.1128 34.7535 20.4413 34.821L28.9711 36.576L54.2116 62.5455L55.9261 71.361C56.0992 72.2306 56.0595 73.1292 55.8104 73.9801C55.5612 74.8311 55.1099 75.6091 54.4951 76.248L46.4378 84.537L42.6016 76.644L42.4441 76.3177L42.1921 76.059L15.9998 49.1107L15.7433 48.8475L7.64109 44.6805L15.8176 36.27C16.2862 35.7808 16.8488 35.3913 17.4717 35.1249C18.0946 34.8585 18.7649 34.7207 19.4423 34.7197ZM19.4423 32.4697C18.4644 32.4726 17.4969 32.6716 16.5972 33.055C15.6975 33.4384 14.8838 33.9983 14.2043 34.7018L3.91284 45.2902L14.3888 50.679L40.5766 77.625L45.8146 88.4025L56.1061 77.814C56.9723 76.9142 57.6087 75.8187 57.9612 74.6205C58.3137 73.4223 58.372 72.1568 58.1311 70.9313L56.2883 61.4588L30.0983 34.5127L20.8913 32.6182C20.4146 32.5198 19.9291 32.47 19.4423 32.4697Z'
        fill='#2172DC'
      />
      <path
        d='M13.3381 53.3745L54.4793 11.0452C57.8768 7.54875 65.7766 5.625 76.7206 5.625C80.0011 5.625 82.7123 5.80275 84.0623 5.91075C84.4201 10.3658 85.5586 29.7315 79.0561 36.423L37.9598 78.7028L13.3381 53.3745Z'
        fill='#EBF0FF'
      />
      <path
        d='M76.7251 6.75C79.3216 6.75 81.5558 6.8625 83.0251 6.9615C83.5808 14.5238 83.5516 30.1815 78.2483 35.6377L37.9598 77.0918L14.9063 53.3745L55.2871 11.8305C58.4708 8.5545 66.0848 6.75 76.7251 6.75ZM76.7251 4.5C69.1088 4.5 58.3651 5.43375 53.6716 10.2622L11.7698 53.3745L37.9598 80.3205L79.8638 37.2083C87.6533 29.196 85.1019 4.8735 85.1019 4.8735C85.1019 4.8735 81.5378 4.5 76.7251 4.5Z'
        fill='#2172DC'
      />
      <path
        d='M46.1251 37.125C44.3349 37.125 42.618 37.8362 41.3521 39.102C40.0863 40.3679 39.3751 42.0848 39.3751 43.875C39.3751 45.6652 40.0863 47.3821 41.3521 48.648C42.618 49.9138 44.3349 50.625 46.1251 50.625C47.9153 50.625 49.6322 49.9138 50.8981 48.648C52.1639 47.3821 52.8751 45.6652 52.8751 43.875C52.8751 42.0848 52.1639 40.3679 50.8981 39.102C49.6322 37.8362 47.9153 37.125 46.1251 37.125Z'
        fill='#AAC7FF'
      />
      <path
        d='M46.1251 38.25C49.2278 38.25 51.7501 40.7723 51.7501 43.875C51.7501 46.9777 49.2278 49.5 46.1251 49.5C43.0223 49.5 40.5001 46.9777 40.5001 43.875C40.5001 40.7723 43.0223 38.25 46.1251 38.25ZM46.1251 36C44.0365 36 42.0335 36.8297 40.5566 38.3065C39.0798 39.7834 38.2501 41.7864 38.2501 43.875C38.2501 45.9636 39.0798 47.9666 40.5566 49.4435C42.0335 50.9203 44.0365 51.75 46.1251 51.75C48.2137 51.75 50.2167 50.9203 51.6936 49.4435C53.1704 47.9666 54.0001 45.9636 54.0001 43.875C54.0001 41.7864 53.1704 39.7834 51.6936 38.3065C50.2167 36.8297 48.2137 36 46.1251 36Z'
        fill='#2172DC'
      />
      <path
        d='M64.1251 19.125C62.3349 19.125 60.618 19.8362 59.3521 21.102C58.0863 22.3679 57.3751 24.0848 57.3751 25.875C57.3751 27.6652 58.0863 29.3821 59.3521 30.648C60.618 31.9138 62.3349 32.625 64.1251 32.625C65.9153 32.625 67.6322 31.9138 68.8981 30.648C70.1639 29.3821 70.8751 27.6652 70.8751 25.875C70.8751 24.0848 70.1639 22.3679 68.8981 21.102C67.6322 19.8362 65.9153 19.125 64.1251 19.125Z'
        fill='#AAC7FF'
      />
      <path
        d='M64.1251 20.25C67.2278 20.25 69.7501 22.7722 69.7501 25.875C69.7501 28.9777 67.2278 31.5 64.1251 31.5C61.0223 31.5 58.5001 28.9777 58.5001 25.875C58.5001 22.7722 61.0223 20.25 64.1251 20.25ZM64.1251 18C62.0365 18 60.0335 18.8297 58.5566 20.3065C57.0798 21.7834 56.2501 23.7864 56.2501 25.875C56.2501 27.9636 57.0798 29.9666 58.5566 31.4435C60.0335 32.9203 62.0365 33.75 64.1251 33.75C66.2137 33.75 68.2167 32.9203 69.6936 31.4435C71.1704 29.9666 72.0001 27.9636 72.0001 25.875C72.0001 23.7864 71.1704 21.7834 69.6936 20.3065C68.2167 18.8297 66.2137 18 64.1251 18Z'
        fill='#2172DC'
      />
    </svg>
  );
}
export default Rocket;

import React from 'react';

interface ArrowProps {
  width?: number | string;
  height?: number | string;
  fill?: string;
}

// eslint-disable-next-line react/function-component-definition
const Arrowdown: React.FC<ArrowProps> = ({ width = 18, height = 18, fill = '#1B5FB7' }) => {
  return (
    // eslint-disable-next-line prettier/prettier
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      fill={fill}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.96967 6.21967C4.26256 5.92678 4.73744 5.92678 5.03033 6.21967L9 10.1893L12.9697 6.21967C13.2626 5.92678 13.7374 5.92678 14.0303 6.21967C14.3232 6.51256 14.3232 6.98744 14.0303 7.28033L9.53033 11.7803C9.23744 12.0732 8.76256 12.0732 8.46967 11.7803L3.96967 7.28033C3.67678 6.98744 3.67678 6.51256 3.96967 6.21967Z'
      />
    </svg>
  );
};

export default Arrowdown;

import DOMPurify from 'dompurify';

const WizardSteps = [
  {
    title: 'Empezá con la selección de firmantes',
    description: (
      <span
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            'Tené en cuenta que solo mostramos a tus <strong>operadores con permisos para autorizar transferencias</strong>. Podés habilitarlos en la sección <strong>Operadores</strong>.',
          ),
        }}
      />
    ),
    target: 'cardListSignatories',
    placement: 'left',
    offsetExtraY: 290,
    offsetExtraX: -100,
  },
  {
    title: 'Confirmá tu selección',
    description: (
      <span
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            'Una vez que selecciones a las personas que quieras agregar a la <strong>Firma 1</strong>, presioná <strong>Agregar firmantes</strong>.',
          ),
        }}
      />
    ),
    target: ['column-one', 'firm', 'cardListSignatories'],
    placement: 'left',
    offsetExtraY: 275,
    offsetExtraX: -100,
  },
  {
    title: 'Esquemas individuales y conjuntos',
    description: (
      <span
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            'Para crear <strong>un esquema individual</strong>, completá solo la <strong>Firma 1</strong>. Si <strong>varias personas tienen que autorizar tus transferencias</strong>, completá también las <strong>Firmas 2 y 3</strong>, según corresponda.',
          ),
        }}
      />
    ),
    target: 'wizard3',
    placement: 'right',
    offsetExtraY: 284,
    offsetExtraX: -100,
  },
  {
    title: 'Cantidad de firmas',
    description: (
      <span
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            'Si completás las <strong>Firmas 1, 2 y 3</strong>, deberán autorizar tres personas de forma conjunta, <strong>una por cada firma</strong>.',
          ),
        }}
      />
    ),
    target: 'wizard3',
    placement: 'right',
    offsetExtraY: 315,
    offsetExtraX: -100,
  },
  {
    title: 'Continuá con la selección de cuentas',
    description: (
      <span
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            'Al continuar, confirmarás a los firmantes del esquema. Tené en cuenta que  <strong>siempre deben coincidir con el poder bancario</strong>.',
          ),
        }}
      />
    ),
    target: 'boxButtons',
    placement: 'bottom',
    offsetExtraY: 130,
    offsetExtraX: 155,
  },
  {
    title: 'Seleccioná las cuentas',
    description: (
      <span
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            ' Mostramos solo las que pueden operar con todos los firmantes agregados. Si tu esquema es ilimitado, este será el último paso. Si tiene topes, tendrás que configurarlos.',
          ),
        }}
      />
    ),
    target: 'accounts',
    placement: 'top',
    offsetExtraY: 125,
    offsetExtraX: 150,
  },
];
export default WizardSteps;

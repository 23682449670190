import { Checkbox, Text } from '@chakra-ui/react';

import { Operator } from '../../interfaces/Operator';
import { CardSectionHorizontal, StyledCard, TextContainer } from './styled';

interface SignatorieCardProps {
  firstName: string;
  lastName: string;
  operator: Operator;
  isSelected: boolean;
  onCheckboxChange: (operator: Operator) => void;
}

function SignatorieCard({ firstName, lastName, operator, isSelected, onCheckboxChange }: SignatorieCardProps) {
  return (
    <StyledCard data-testid='fetch-signers'>
      <CardSectionHorizontal>
        <Checkbox
          role='checkbox'
          data-testid={`checkbox-${firstName}-${lastName}`}
          isChecked={isSelected}
          onChange={() => onCheckboxChange(operator)}
        />
        <TextContainer>
          <Text data-testid={`fetch-signers-${firstName}-${lastName}`} textStyle='labelLgBold'>
            {firstName} {lastName}
          </Text>
        </TextContainer>
      </CardSectionHorizontal>
    </StyledCard>
  );
}

export default SignatorieCard;

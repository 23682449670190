/* eslint-disable import/prefer-default-export */
import { Card, CardSection } from '@wow/card';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
  width: 100%;
  flex-direction: row !important;
  border: none !important;
  margin-top: 4px;
  border-radius: 8px !important;
`;

export const CardSectionHorizontal = styled(CardSection)``;

export const TextContainer = styled.div`
  margin-left: 8px;
`;

/* eslint-disable import/prefer-default-export */
import { CardSection } from '@wow/card';
import styled from 'styled-components';

export const Container = styled.div`
  .headline {
    > p {
      line-height: 24px;
      margin-top: 12px;
    }
  }
`;

export const ContainerImg = styled.div`
  margin-left: 12px;
  margin-right: 4px;
`;

export const TextContainer = styled.div``;

export const CardSectionHorizontal = styled(CardSection)`
  gap: 12px;
  ${TextContainer} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3px;
    flex: 1 0 0;
  }
`;

export const ContainerEmptyStateScreen = styled.div`
  margin-top: 72px;
  & button {
    background-color: transparent;
    color: #2172dc;
    text-decoration: underline;
    border: none;
    box-shadow: none;
    &:hover {
      background-color: transparent;
      color: #2172dc;
      text-decoration: underline;
    }
  }
`;

/* eslint-disable import/no-unresolved */
import styled from 'styled-components';

const Panel = styled.div`
  .footer-container {
    background: #ffffff;
    box-shadow: 0px -3px 50px -12px rgba(19, 28, 44, 0.25);
    position: fixed;
    bottom: 0px;
    width: 100%;
    right: -6px;
    height: 4.5rem;
    display: grid;
    align-items: center;
  }
  .steps {
    font-size: 14px !important;
    font-family: Inter !important;
    font-weight: 400 !important;
    margin-right: 1rem !important;
    -webkit-align-self: center !important;
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .wizardButtons {
    background: rgb(255, 255, 255);
    box-shadow: rgba(19, 28, 44, 0.25) 0px 25px 50px -12px;
    position: relative;
    bottom: -145px;
    width: 112%;
    right: 95px;
    height: 4.5rem;
    display: grid;
    -webkit-box-align: center;
    align-items: center;
  }
  .boxButtons {
    padding: 5px;
  }
`;

export default Panel;

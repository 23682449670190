const handleWizard = (
  currentStep,
  modalWelcome,
  setFilteredData,
  setHeight,
  setWidth,
  setSelectedItems,
  setItemsColumnOne,
  setItemsColumnTwo,
  setItemsColumnThree,
  setZIndex,
  operatorsMock,
  operatorsMock2,
  navigate,
  routesNames,
) => {
  if (currentStep === 0 && modalWelcome === true) {
    setFilteredData(operatorsMock);
    setHeight(264);
    setWidth(305);
    setSelectedItems([]);
  } else if (currentStep === 1 && modalWelcome === true) {
    setFilteredData(operatorsMock);
    setHeight(220);
    setWidth(304);
    setSelectedItems(prev => [...prev, operatorsMock[0]]);
    setItemsColumnOne([]);
    setItemsColumnTwo([]);
    setItemsColumnThree([]);
  } else if (currentStep === 2 && modalWelcome === true) {
    setFilteredData(operatorsMock2);
    setHeight(284);
    setWidth(304);
    setSelectedItems([]);
    setItemsColumnOne([operatorsMock[0]]);
    setItemsColumnTwo([operatorsMock[1], operatorsMock[3]]);
    setItemsColumnThree([operatorsMock[2]]);
  } else if (currentStep === 3 && modalWelcome === true) {
    setZIndex(false);

    setFilteredData(operatorsMock2);

    setHeight(220);
    setWidth(304);
    setSelectedItems([]);
    setItemsColumnOne([operatorsMock[0], operatorsMock[1]]);
    setItemsColumnTwo([operatorsMock[2], operatorsMock[3]]);
    setItemsColumnThree([operatorsMock[4], operatorsMock[5]]);
  } else if (currentStep === 4 && modalWelcome === true) {
    setZIndex(true);
    setFilteredData(operatorsMock2);
    setHeight(264);
    setWidth(296);
    setSelectedItems([]);
    setItemsColumnOne([operatorsMock[0], operatorsMock[1]]);
    setItemsColumnTwo([operatorsMock[2], operatorsMock[3]]);
    setItemsColumnThree([operatorsMock[4], operatorsMock[5]]);
  } else if (currentStep === 5 && modalWelcome === true) {
    navigate(`/${routesNames.Account}`);
  }
};
export default handleWizard;

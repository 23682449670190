import { BreadcrumbComponent } from '@wow/breadcrumb';
import { useLocation, useNavigate } from 'react-router-dom';

import { CONST_BREADCRUMB } from '../../../constants';
import PanelBreadCrumb from './styles';

function Breadcrumb() {
  const location = useLocation();
  const navigate = useNavigate();
  const routesBreadcrumb = [
    {
      path: '/esquemasdefirmas',
      breadcrumb: [
        ...CONST_BREADCRUMB,
        {
          key: 'esquema',
          id: 'esquema',
          label: 'Esquemas de firmas',
          link: '',
          isCurrentPage: true,
        },
      ],
    },
    {
      path: '/esquemasdefirmas/selection-signatories',
      breadcrumb: [
        ...CONST_BREADCRUMB,
        {
          key: 'esquema',
          id: 'esquema',
          label: 'Esquemas de firmas',
          link: '/esquemasdefirmas',
          onClick: e => {
            e.preventDefault();
            navigate('/esquemasdefirmas');
          },
        },
        {
          key: 'esquema',
          id: 'esquema',
          label: 'Crear esquemas',
          link: '',
          isCurrentPage: true,
        },
      ],
    },
    {
      path: '/esquemasdefirmas/selection-signatories/account',
      breadcrumb: [
        ...CONST_BREADCRUMB,

        {
          key: 'esquema',
          id: 'esquema',
          label: 'Esquemas de firmas',
          link: '/esquemasdefirmas',
          onClick: e => {
            e.preventDefault();
            navigate('/esquemasdefirmas');
          },
        },
        {
          key: 'esquema',
          id: 'esquema',
          label: 'Crear esquemas',
          link: '',
          isCurrentPage: true,
        },
      ],
    },
  ];
  const matchingRoute = routesBreadcrumb.find(route => route?.path === location?.pathname);
  return (
    <PanelBreadCrumb>
      <BreadcrumbComponent data-testid='breadcrumb' mt='4px' items={matchingRoute ? matchingRoute.breadcrumb : CONST_BREADCRUMB} />
    </PanelBreadCrumb>
  );
}

export default Breadcrumb;

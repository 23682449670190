/* eslint-disable import/no-unresolved */
import styled from 'styled-components';

const PanelBreadCrumb = styled.div`
  .css-oekz15 {
    pointer-events: none;
  }
`;

export default PanelBreadCrumb;

function ExternalLink() {
  return (
    <svg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.1665 2.50008C12.1665 2.03984 12.5396 1.66675 12.9998 1.66675H17.9998C18.4601 1.66675 18.8332 2.03984 18.8332 2.50008V7.50008C18.8332 7.96032 18.4601 8.33342 17.9998 8.33342C17.5396 8.33342 17.1665 7.96032 17.1665 7.50008V4.51193L9.42243 12.256C9.09699 12.5814 8.56935 12.5814 8.24392 12.256C7.91848 11.9306 7.91848 11.4029 8.24392 11.0775L15.988 3.33341H12.9998C12.5396 3.33341 12.1665 2.96032 12.1665 2.50008Z'
        fill='white'
      />
      <path
        d='M4.07725 6.07749C4.23353 5.92121 4.44549 5.83342 4.6665 5.83342H9.6665C10.1267 5.83342 10.4998 5.46032 10.4998 5.00008C10.4998 4.53984 10.1267 4.16675 9.6665 4.16675H4.6665C4.00346 4.16675 3.36758 4.43014 2.89874 4.89898C2.4299 5.36782 2.1665 6.00371 2.1665 6.66675V15.8334C2.1665 16.4965 2.4299 17.1323 2.89874 17.6012C3.36758 18.07 4.00346 18.3334 4.6665 18.3334H13.8332C14.4962 18.3334 15.1321 18.07 15.6009 17.6012C16.0698 17.1323 16.3332 16.4965 16.3332 15.8334V10.8334C16.3332 10.3732 15.9601 10.0001 15.4998 10.0001C15.0396 10.0001 14.6665 10.3732 14.6665 10.8334V15.8334C14.6665 16.0544 14.5787 16.2664 14.4224 16.4227C14.2661 16.5789 14.0542 16.6667 13.8332 16.6667H4.6665C4.44549 16.6667 4.23353 16.5789 4.07725 16.4227C3.92097 16.2664 3.83317 16.0544 3.83317 15.8334V6.66675C3.83317 6.44573 3.92097 6.23377 4.07725 6.07749Z'
        fill='white'
      />
    </svg>
  );
}

export default ExternalLink;

/* eslint-disable import/prefer-default-export */
import { keyframes } from '@chakra-ui/react';
import { CardSection } from '@wow/card';
import styled from 'styled-components';

import { body16bold } from '../../styled/generalStyles.';

const backgroundTransition = keyframes`
  0% {
    background-color: #BBD5F6;
  }
  100% {
    background-color: #E8F1FC;
  }
`;

export const Container = styled.div`
  .headline {
    > p {
      line-height: 24px;
      margin-top: 12px;
    }
  }

  .headlineWithIcon {
    display: flex;
    align-items: flex-end;
    > p {
      line-height: 24px;
      margin-top: 12px;
    }
  }

  .headlineWithSpan {
    display: flex;
  }

  .cardListSignatories {
    border: none;
    padding: 10px !important;
    height: 590px;
    overflow-y: auto;
    padding: 2px;
  }
  .zindex {
    z-index: 1;
  }

  .card-title-container {
    background: #e8f1fc;
    height: 3rem;
    align-content: center;
    border-radius: 8px;
    transition: background-color 1s ease;

    ${props =>
      props.backgroundChange &&
      `
      background-color: #BBD5F6;
    `}
    &.transitioning {
      animation: ${backgroundTransition} 1s ease;
    }
  }

  .card-container {
    margin-top: 3.8rem;
  }
`;

export const CardSectionHorizontal = styled(CardSection)`
  padding: 0 !important;
  flex-direction: column;
`;

export const Operators = styled.span`
  ${body16bold};
`;

export const InputContainer = styled.div`
  margin-bottom: 12px;
  width: -webkit-fill-available;
`;

export const TextBold = styled.span`
  ${body16bold};
`;

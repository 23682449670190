const evaluateStyles = (itemsColumnOne, itemsColumnTwo, itemsColumnThree, selectedItems, stylesPresets) => {
  if (selectedItems.length === 0) {
    if (itemsColumnOne.length > 0 && itemsColumnTwo.length === 0 && itemsColumnThree.length === 0) {
      return stylesPresets.nothingSelectedOneColumn;
    }
    if (itemsColumnOne.length > 0 && itemsColumnTwo.length > 0 && itemsColumnThree.length === 0) {
      return stylesPresets.nothingSelectedTwoColumns;
    }
    if (itemsColumnOne.length === 0 && itemsColumnTwo.length === 0 && itemsColumnThree.length === 0) {
      return stylesPresets.nothingSelectedEmptyColumns;
    }
    return stylesPresets.nothingSelectedThreeColumns;
  }
  if (selectedItems.length > 0) {
    if (itemsColumnOne.length === 0 && itemsColumnTwo.length === 0 && itemsColumnThree.length === 0) {
      return stylesPresets.selectedWithEmptyColumns;
    }
    if (itemsColumnOne.length > 0 && itemsColumnTwo.length === 0 && itemsColumnThree.length === 0) {
      return stylesPresets.selectedWithOneColumn;
    }
    if (itemsColumnOne.length > 0 && itemsColumnTwo.length > 0 && itemsColumnThree.length === 0) {
      return stylesPresets.selectedWithTwoColumns;
    }
    return stylesPresets.selectedWithThreeColumns;
  }

  return stylesPresets.nothingSelectedThreeColumns;
};

export default evaluateStyles;

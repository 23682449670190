const finishWizard = (
  setSelectedItemsAccount,
  setFilteredData,
  setItemsColumnOne,
  setItemsColumnTwo,
  setItemsColumnThree,
  navigate,
  routesNames,
) => {
  setSelectedItemsAccount([]);
  setFilteredData([]);
  setItemsColumnOne([]);
  setItemsColumnTwo([]);
  setItemsColumnThree([]);
  localStorage.setItem('esquema-wizard', 'true');
  return navigate(`/${routesNames.Home}`);
};
export default finishWizard;

import { useQuery } from 'react-query';

import { getValidatePreConditions } from '../../services/api';
import { CommonErrorResponse } from '../interfaces/IErrorResponse';
import { ValidatePreConditionsResponse } from '../interfaces/IValidatePreConditionsResponse';

export default function useValidatePreConditions() {
  return useQuery<ValidatePreConditionsResponse, CommonErrorResponse>({
    queryKey: ['getValidatePreConditions'],
    queryFn: async () => {
      const response = await getValidatePreConditions();
      return response.data;
    },
    enabled: false,
  });
}

/* eslint-disable @typescript-eslint/no-unused-vars */

import { Grid, GridItem, Icon, Text, useDisclosure } from '@chakra-ui/react';
// eslint-disable-next-line import/no-unresolved
import { OpenLink } from '@react-ib-mf/style-guide-ui';
import { IconButtonComponent } from '@wow/button';
import { Card } from '@wow/card';
import { ErrorContentComponent, ErrorContentProps } from '@wow/error-content';
import { useContext, useEffect, useState } from 'react';
import { ChevronRight } from 'react-feather';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router';

import { getWhiteList } from '../../../services/api';
import EditDocument from '../../assets/images/EditDocument';
import SignUp from '../../assets/images/SignUp';
import EmptyStateScreen from '../../components/EmptyStateScreen';
import WelcomeModal from '../../components/welcomeModal';
import routesNames from '../../constants/routesNames';
import AppContext from '../../context/Provider';
import useValidatePreConditions from '../../hooks/useValidatePreConditions';
import { ErrorServiceText, HomePageText } from '../../text/GeneralText';
import { CardSectionHorizontal, Container, ContainerEmptyStateScreen, ContainerImg, TextContainer } from './styled';

function Home() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isHasPermissionViewCard, setIsHasPermissionViewCard] = useState(false);
  const { showErrorContent, setShowErrorContent, permissions, setModalWelcome, currentStep, initialized, whiteList, setWhiteList } =
    useContext(AppContext);
  const [errorService, setErrorService] = useState(false);

  const errorContentProps: ErrorContentProps = {
    titleText: ErrorServiceText.ErrorContentTitleText,
    bodyText: ErrorServiceText.ErrorContentBodyText,
    linkText: ErrorServiceText.ErrorContentLinkText,
    isRouter: true,
    accessError: 'content',
    callbackAction: () => handleGoBackHome(),
  };

  const {
    data: dataWhiteList,
    isSuccess: isSuccessWhiteList,
    isLoading: isLoadingWhiteList,
    isError: isErrorWhiteList,
  } = useQuery({
    queryKey: ['getSubscriptionConfirm'],
    queryFn: () => getWhiteList(),
  });

  setWhiteList(isSuccessWhiteList === true ? dataWhiteList?.data?.data.admEsquemaFirmasSeccionAdministrativaAlta : false);

  useEffect(() => {
    setIsHasPermissionViewCard(permissions?.administracionEsquemasFirmasMantenimientoAlta || false);
  }, [permissions]);

  const navigate = useNavigate();
  const handleGoBackHome = () => {
    setShowErrorContent(false);
    setErrorService(false);
  };

  const url = `${process.env.SIB_ESQUEMAS_FIRMAS_BUSCAR}`;
  const { refetch } = useValidatePreConditions();

  useEffect(() => {
    const esquemaWizard = localStorage.getItem('esquema-wizard');
    if (initialized) {
      window.location.reload();
    }

    if (whiteList) {
      if (esquemaWizard === null) {
        onOpen();
      } else {
        setModalWelcome(false);
      }
    }
  }, [onOpen, isLoadingWhiteList, whiteList]);


  const handleClick = async () => {
    const { data, error, isError } = await refetch();

    if (isError) {
      const status = error?.response?.status;
      if (status === 409) {
        setShowErrorContent(true);
        return;
      }
      if (status === 500) {
        setErrorService(true);
        return;
      }
    }

    if (data?.success) {
      navigate(`/${routesNames.SelectionSignatories}`);
    }
  };

  if (showErrorContent) {
    return (
      <ContainerEmptyStateScreen textStyle='labelLgBoldUnderline'>
        <EmptyStateScreen page='HOME' variant='ACCESS_NO_DATA' hasButton onRetry={handleGoBackHome} />
      </ContainerEmptyStateScreen>
    );
  }

  if (errorService) {
    return <ErrorContentComponent ErrorContentProps={errorContentProps} />;
  }

  return (
    <Container>
      <Grid mt='1.5rem' templateColumns='repeat(12, 1fr)' gap={6}>
        <GridItem className='headline' colSpan={12}>
          <Text textStyle='h1'>{HomePageText.homeTitle}</Text>
          <Text textStyle='bodyMd'>{HomePageText.homeSubtitle}</Text>
        </GridItem>

        {whiteList && isHasPermissionViewCard && (
          <GridItem colSpan={6}>
            <Card>
              <CardSectionHorizontal>
                <ContainerImg>
                  <SignUp />
                </ContainerImg>
                <TextContainer>
                  <Text textStyle='titleMdBold'>{HomePageText.cardCreateTitle}</Text>
                  <Text textStyle='bodyMd'>{HomePageText.cardCreateSubtitle}</Text>
                </TextContainer>
                <IconButtonComponent
                  aria-label='icon-button'
                  variant='icon-text'
                  size='i-lg'
                  icon={<Icon as={ChevronRight} />}
                  onClick={handleClick}
                  data-testid='navigate-to-create'
                />
              </CardSectionHorizontal>
            </Card>
          </GridItem>
        )}
        {(isErrorWhiteList || isSuccessWhiteList) && (
          <GridItem colSpan={6}>
            <Card>
              <CardSectionHorizontal>
                <ContainerImg>
                  <EditDocument />
                </ContainerImg>
                <TextContainer>
                  <Text textStyle='titleMdBold'>{HomePageText.cardEditTitle}</Text>
                  <Text textStyle='bodyMd'>{HomePageText.cardEditSubtitle}</Text>
                </TextContainer>
                <OpenLink url={url} openMode='newTab' reuseTab>
                  <IconButtonComponent
                    aria-label='icon-button'
                    variant='icon-text'
                    size='i-lg'
                    icon={<Icon as={ChevronRight} />}
                    data-testid='navigate-to-edit'
                  />
                </OpenLink>
              </CardSectionHorizontal>
            </Card>
          </GridItem>
        )}
      </Grid>
      <WelcomeModal isOpen={isOpen} onClose={onClose} />
    </Container>
  );
}

export default Home;

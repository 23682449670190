/* eslint-disable import/no-unresolved */
import styled from 'styled-components';

const Panel = styled.div`
.container-filter {
 border:1px solid #ccc;
  display:flex;
  align-items: center;
  height:64px;
  border-radius: 8px;
  margin-top:15px
} 
  .spacer {
  margin-left: 15px;
  margin-right:10px
  }
.container-modal {
  box-shadow: 5px 3px 18px 0px rgba(169,169,169,0.75);
-webkit-box-shadow: 5px 3px 18px 0px rgba(169,169,169,0.75);
-moz-box-shadow: 5px 3px 18px 0px rgba(169,169,169,0.75);
  padding: 1rem;
  border-radius:8px;
  background: white;
  maxWidth: 400px;
  position: absolute;
  z-index: 10;
  margin-left: 94px;
  margin-top: -15px;
}
   .container-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    align-items: center;
  }
    .line {
    margin-bottom: 17px;
    width: 110%;
    margin-left: -5%;
    }
    .txt-mar{
    margin-bottom:15px;
    margin-top:3px
    }
    .filtered {
    display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-left:-5px
  }
  }
`;

export default Panel;

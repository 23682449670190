import { Button, ModalProps } from '@chakra-ui/react';
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@wow/modal';
import React, { useContext } from 'react';

import AppContext from '../../context/Provider';

interface ModalStatesProps extends ModalProps {
  headerText?: React.ReactNode;
  bodyText?: React.ReactNode;
  buttonName1?: React.ReactNode;
  buttonName2?: React.ReactNode;
  onCancel?: () => void;
  onConfirm?: () => void;
  state: 'success' | 'error' | 'warning' | 'info';
}

function ModalCreateSchemaError({ state, bodyText, buttonName1, onCancel, buttonName2, onConfirm, ...props }: ModalStatesProps) {
  const [header] = React.Children.toArray(props.children);
  const { resetColumns } = useContext(AppContext);

  return (
    <Modal
      closeOnOverlayClick={false}
      data-testid='modal-create-schema-error'
      isOpen={props.isOpen}
      onClose={props.onClose}
      variant='states'
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader state={state}>{header}</ModalHeader>
        <ModalBody>{bodyText}</ModalBody>
        <ModalFooter>
          <Button variant='primary-outline' onClick={onCancel}>
            {buttonName1}
          </Button>
          <Button
            variant='primary'
            onClick={() => {
              resetColumns();
              onConfirm();
            }}
          >
            {buttonName2}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ModalCreateSchemaError;

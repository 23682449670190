const defaultStyles = {
  borderSolid: false,
  borderDottedBold: false,
  borderDotted: false,
  borderDottedBlue: false,
  backgroundBlue: false,
  buttonAddSigners: false,
  colorDarkTitle: false,
};
export default defaultStyles;

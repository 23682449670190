import { Alert, AlertDescription, AlertTitle, Button, ModalProps, Text } from '@chakra-ui/react';
// eslint-disable-next-line import/no-unresolved
import { OpenLink } from '@react-ib-mf/style-guide-ui';
import { AlertComponent } from '@wow/alert';
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@wow/modal';
import React, { useContext } from 'react';

import ExternalLink from '../../assets/icons/externalLink';
import AppContext from '../../context/Provider';
import { ModalAlertText, ModalTextCreateSchema } from '../../text/GeneralText';

interface ModalStatesProps extends ModalProps {
  headerText?: React.ReactNode;
  bodyText?: React.ReactNode;
  buttonName1?: React.ReactNode;
  buttonName2?: React.ReactNode;
  onCancel?: () => void;
  onConfirm?: () => void;
  state: 'success' | 'error' | 'warning' | 'info';
}

function ModalCreateSchemaSuccess({ state, bodyText, buttonName1, onCancel, buttonName2, onConfirm, ...props }: ModalStatesProps) {
  const [header] = React.Children.toArray(props.children);
  const { resetColumns } = useContext(AppContext);
  const url = `${process.env.SIB_ESQUEMAS_FIRMAS_BUSCAR}`;

  return (
    <Modal
      data-testid='modal-create-schema-success'
      isOpen={props.isOpen}
      onClose={props.onClose}
      variant='states'
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent width='32.5rem' maxWidth='36rem' className='width-modal'>
        <ModalHeader state={state}>{header}</ModalHeader>
        <ModalBody>{bodyText}</ModalBody>

        <Alert background='#ffffff' padding='0'>
          <AlertComponent padding='0' minHeight='0' minWidth='0' maxHeight='400px' id='alert-id' variant='warning'>
            <AlertTitle textStyle='titleSmBold'>{ModalAlertText.headerText}</AlertTitle>
            <AlertDescription lineHeight='1rem'>{ModalAlertText.bodyText}</AlertDescription>
          </AlertComponent>
        </Alert>

        <Text textStyle='bodySm' color='#0381A8'>
          {ModalTextCreateSchema.descriptions}
        </Text>

        <ModalFooter>
          <Button variant='primary-outline' onClick={onCancel}>
            {buttonName1}
          </Button>

          <OpenLink url={url} openMode='newTab' reuseTab>
            <Button
              rightIcon={<ExternalLink />}
              variant='primary'
              onClick={() => {
                resetColumns();
                onConfirm();
              }}
            >
              {buttonName2}
            </Button>
          </OpenLink>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ModalCreateSchemaSuccess;

import { Button, CloseButton, ModalProps } from '@chakra-ui/react';
import { Modal, ModalBody, ModalChakra, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@wow/modal';
import React, { useContext } from 'react';

import AppContext from '../../context/Provider';

interface ModalStatesProps extends ModalProps {
  headerText?: React.ReactNode;
  bodyText?: React.ReactNode;
  buttonName1?: React.ReactNode;
  buttonName2?: React.ReactNode;
  onCancel?: () => void;
  onConfirm?: () => void;
}

function ModalState({ bodyText, buttonName1, onCancel, buttonName2, onConfirm, ...props }: ModalStatesProps) {
  const [header] = React.Children.toArray(props.children);
  const { resetColumns } = useContext(AppContext);
  return (
    <Modal data-testid='modal' isOpen={props.isOpen} onClose={props.onClose} variant='states'>
      <ModalOverlay />
      <ModalContent>
        <CloseButton onClick={props.onClose} sx={ModalChakra.close} />
        <ModalHeader state='warning'>{header}</ModalHeader>
        <ModalBody>{bodyText}</ModalBody>
        <ModalFooter>
          <Button variant='primary-outline' onClick={onCancel}>
            {buttonName1}
          </Button>
          <Button
            variant='primary'
            onClick={() => {
              resetColumns();
              onConfirm();
            }}
          >
            {buttonName2}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ModalState;

import { Box, Button, Checkbox, IconButton, Input, InputGroup, InputLeftElement, Text } from '@chakra-ui/react';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import Arrow from '../../assets/icons/arrowdown';
import Close from '../../assets/icons/close';
import Search from '../../assets/icons/search';
import { FilterTagProps } from '../../interfaces/Filtertag';
import { AccountsData } from '../../interfaces/ResposeDataAccouts';
import handleCancelButtonClick from '../../utils/handleCancelButtonClick';
import Panel from './styles';

// eslint-disable-next-line react/function-component-definition
const FilterTag: React.FC<FilterTagProps> = ({ banks, onChange, disabled }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<Record<string, boolean>>({});
  const [search, setSearch] = useState<string>('');
  const modalRef = useRef<HTMLDivElement>(null);

  const isApplyButtonDisabled = Object.values(selectedItems).every(value => !value);
  const count = useMemo(() => Object.values(selectedItems).filter(value => value).length, [selectedItems]);
  const buttonVariant = isOpen || count > 0 ? 'primary-dark' : 'secondary-outline';

  const handleClickOpen = () => setIsOpen(prev => !prev);

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleApplyButtonClick = () => {
    const selectedBanks = Object.entries(selectedItems).reduce((acc, [key, value]) => {
      if (value) acc.add(key);
      return acc;
    }, new Set<string>());

    const filteredAccounts = banks.filter(bank => selectedBanks.has(bank.bankShortName));
    onChange(new Set(filteredAccounts.map(bank => bank.bankShortName)));
    setIsOpen(false);
  };

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = event.target;

    const regex = /^[A-Za-zÀ-ÿ\s]*$/;
    if (regex.test(value) && !/[ñÑ]/.test(value)) {
      value = value.replace(/\s{2,}/g, ' ');
      setSearch(value);
    }
  };

  const handleCheckboxChange = (bank: AccountsData) => {
    setSelectedItems(prev => ({
      ...prev,
      [bank.bankShortName]: !prev[bank.bankShortName],
    }));
  };

  const filteredBanks = useMemo(() => {
    const normalizeText = (text: string) =>
      text
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();
    const normalizedSearch = normalizeText(search);
    const uniqueBanksMap = new Map(banks.map(bank => [bank.bankShortName, bank]));
    const uniqueBanks = Array.from(uniqueBanksMap.values());
    return uniqueBanks
      .filter(bank => normalizeText(bank.bankShortName).includes(normalizedSearch))
      .sort((a, b) => a.bankShortName.localeCompare(b.bankShortName));
  }, [banks, search]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <Panel>
      <Box className='container-filter'>
        <Text textStyle='labelMdSemi' className='spacer'>
          Filtrá por
        </Text>
        <Button
          onClick={handleClickOpen}
          isDisabled={disabled}
          variant={buttonVariant}
          size='sm'
          rightIcon={<Arrow fill={buttonVariant === 'primary-dark' ? '#1B5FB7' : '#565E71'} />}
        >
          Banco {count > 0 ? `(${count})` : ''}
        </Button>
      </Box>

      {isOpen && (
        <Box className='container-modal' ref={modalRef}>
          <Box className='container-header'>
            <Text textStyle='labelLgBold'>Buscar por Banco</Text>
            <IconButton size='sm' variant='ghost' aria-label='Cerrar' icon={<Close />} onClick={handleCloseModal} />
          </Box>
          <hr className='line' />

          <InputGroup>
            <InputLeftElement pointerEvents='none'>
              <Search />
            </InputLeftElement>
            <Input placeholder='Buscar banco' value={search} onChange={handleSearchInputChange} />
          </InputGroup>
          <Text textStyle='labelSm' className='txt-mar'>
            Ingresá las primeras letras del banco
          </Text>

          <Box maxH='200px' overflowY='auto' p={2}>
            {filteredBanks.length === 0 ? (
              <Text textAlign='center' color='gray.500'>
                No hay resultados
              </Text>
            ) : (
              filteredBanks.map(bank => (
                <Box key={bank.bankShortName} className='filtered'>
                  <Checkbox
                    isChecked={!!selectedItems[bank.bankShortName]}
                    onChange={() => handleCheckboxChange(bank)}
                    aria-label={bank.bankShortName}
                  >
                    {bank.bankShortName}
                  </Checkbox>
                </Box>
              ))
            )}
          </Box>
          <hr className='line' />
          <Box display='flex' justifyContent='space-between' mt={4}>
            <Button
              onClick={() => handleCancelButtonClick(setSelectedItems, setSearch, setIsOpen, onChange, banks)}
              isDisabled={isApplyButtonDisabled}
              variant='primary-text'
              size='md'
            >
              Borrar
            </Button>
            <Button onClick={handleApplyButtonClick} isDisabled={isApplyButtonDisabled} colorScheme='blue' size='md'>
              Aplicar
            </Button>
          </Box>
        </Box>
      )}
    </Panel>
  );
};

export default FilterTag;

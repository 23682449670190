// eslint-disable-next-line import/no-unresolved
import { ErrorContentComponent, ErrorContentProps } from '@wow/error-content';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import routesNames from './constants/routesNames';
import AppContextProvider from './context/AppContextProvider';
import SignatureSchemeLayout from './layouts/signatureSchemeLayout';
import { ErrorContentText } from './text/GeneralText';
import Accounts from './views/Accounts';
import Home from './views/Home';
import SelectionSignatories from './views/SelectionSignatories';

function App() {
  const errorContentProps: ErrorContentProps = {
    titleText: ErrorContentText.ErrorContentTitleText,
    bodyText: ErrorContentText.ErrorContentBodyText,
    linkText: ErrorContentText.ErrorContentLinkText,
    redirectTo: '/',
    isRouter: true,
    accessError: 'content',
  };
  return (
    <AppContextProvider>
      <Router>
        <Routes>
          <Route path='/' element={<SignatureSchemeLayout />}>
            <Route path={routesNames.Home} element={<Home />} />
            <Route path={routesNames.SelectionSignatories} element={<SelectionSignatories />} />
            <Route path={routesNames.Account} element={<Accounts />} />
          </Route>
          <Route path='*' element={<ErrorContentComponent ErrorContentProps={errorContentProps} />} />
        </Routes>
      </Router>
    </AppContextProvider>
  );
}

export default App;

import { Button, CloseButton, Text } from '@chakra-ui/react';
import {
  Modal,
  ModalBody,
  ModalBox,
  ModalChakra,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalLeftBox,
  ModalOverlay,
  ModalRightBox,
} from '@wow/modal';
import { useContext } from 'react';
import { useNavigate } from 'react-router';

import imageWizard from '../../assets/images/imageWizard.svg';
import routesNames from '../../constants/routesNames';
import AppContext from '../../context/Provider';
import { initWizard, seeLater } from '../../utils/welcomeModalUtils';

interface ModalWelecome {
  isOpen: boolean;
  onClose: () => void;
}
function WelcomeModal({ isOpen, onClose }: ModalWelecome) {
  const navigate = useNavigate();
  const { setModalWelcome } = useContext(AppContext);
  return (
    <Modal isCentered isOpen={isOpen} onClose={() => seeLater(setModalWelcome, onClose)} closeOnOverlayClick={false} variant='welcome'>
      <ModalOverlay />
      <ModalContent>
        <CloseButton onClick={onClose} sx={ModalChakra.close} />
        <ModalBox>
          <ModalLeftBox>
            <ModalHeader>
              <Text textStyle='h3'>Simplificamos la creación de esquemas de firmas</Text>
            </ModalHeader>
            <ModalBody>
              <Text>Ahora la carga de firmantes y cuentas para tus esquemas es más ágil e intuitiva, ¡explorá lo nuevo!</Text>
            </ModalBody>
            <ModalFooter mb='25px'>
              <Button size='md' variant='primary-outline' onClick={() => seeLater(setModalWelcome, onClose)}>
                Ver más tarde
              </Button>
              <Button size='md' variant='primary' onClick={() => initWizard(setModalWelcome, navigate, onClose, routesNames)}>
                Explorar
              </Button>
            </ModalFooter>
          </ModalLeftBox>
          <ModalRightBox mt='37px'>
            <img src={imageWizard} alt='img' />
          </ModalRightBox>
        </ModalBox>
      </ModalContent>
    </Modal>
  );
}
export default WelcomeModal;

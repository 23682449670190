import styled from 'styled-components';

const Panel = styled.div`
  padding-left: 22px;
  padding-right: 30px;
  padding-top: 10px;
  height: 100%;
  position: relative;
  width: 95%;
  margin-bottom: 150px;
`;
export default Panel;

function EditDocument() {
  return (
    <svg width='40' height='41' viewBox='0 0 40 41' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='edit-document'>
        <g id='Vector'>
          <path d='M2.5 37V4H37.5V24.293L24.793 37H2.5Z' fill='white' />
          <path d='M37 4.5V24.086L24.586 36.5H3V4.5H37ZM38 3.5H2V37.5H25L38 24.5V3.5Z' fill='#2172DC' />
          <path d='M38 24.5V19.5H37V25.5L38 24.5ZM26 36.5H20V37.5H25L26 36.5Z' fill='#E8F1FC' />
          <path d='M3 4.5H37V9.5H3V4.5Z' fill='#94BCF0' />
          <path
            d='M37.5 20.5C37.3675 20.4997 37.2405 20.447 37.1467 20.3533C37.053 20.2596 37.0003 20.1325 37 20V18C37 17.725 37.225 17.5 37.5 17.5C37.775 17.5 38 17.725 38 18V20C38 20.275 37.775 20.5 37.5 20.5ZM18.5 36.5H20.5C20.775 36.5 21 36.725 21 37C21 37.275 20.775 37.5 20.5 37.5H18.5C18.3675 37.4997 18.2404 37.447 18.1467 37.3533C18.053 37.2595 18.0003 37.1325 18 37C18 36.725 18.225 36.5 18.5 36.5Z'
            fill='#2172DC'
          />
          <path d='M24.265 35.174L25.931 33.508L29.216 36.369L27.346 38.239L22.78 39.723L24.265 35.174Z' fill='#94BCF0' />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M25.2556 39.4436L27.614 38.677L29.948 36.343L25.907 32.824L23.826 34.905L22 40.502L22.0029 40.501L22.002 40.504L25.258 39.446L25.2556 39.4436ZM28.483 36.394L25.954 34.191L24.703 35.442L23.8532 38.047L24.4596 38.6509L27.077 37.8L28.483 36.394Z'
            fill='#2172DC'
          />
          <path
            d='M34.906 24.521L35.791 23.637C36.201 23.227 36.746 23.001 37.327 23.001C37.7564 23.0017 38.176 23.1295 38.5328 23.3684C38.8897 23.6073 39.1678 23.9465 39.3321 24.3432C39.4964 24.7399 39.5395 25.1764 39.456 25.5976C39.3725 26.0189 39.1662 26.4059 38.863 26.71L37.979 27.594L34.906 24.521Z'
            fill='#E8F1FC'
          />
          <path
            d='M37.327 23.5C37.774 23.5 38.194 23.674 38.51 23.99C38.826 24.306 39 24.726 39 25.173C39 25.62 38.826 26.04 38.51 26.356L37.98 26.886L35.614 24.52L36.144 23.99C36.46 23.674 36.88 23.5 37.327 23.5ZM37.327 22.5C36.643 22.5 35.959 22.761 35.437 23.283L34.2 24.52L37.98 28.3L39.217 27.063C39.5908 26.6892 39.8453 26.2129 39.9485 25.6944C40.0516 25.1759 39.9986 24.6385 39.7963 24.1501C39.5941 23.6617 39.2515 23.2443 38.8119 22.9505C38.3724 22.6568 37.8556 22.5 37.327 22.5Z'
            fill='#2172DC'
          />
          <path
            d='M27.893 37.691C27.8578 37.6344 27.8183 37.5806 27.775 37.53C27.554 37.272 27.253 37.106 26.875 37.035C26.699 36.242 26.072 35.824 25.491 35.675C25.4493 35.4655 25.3643 35.2671 25.2415 35.0923C25.1187 34.9176 24.9609 34.7703 24.778 34.66L33.294 26.135L36.367 29.208L27.893 37.691Z'
            fill='#E8F1FC'
          />
          <path
            d='M33.293 26.842L35.659 29.208L27.909 36.967C27.7211 36.8158 27.5073 36.6999 27.278 36.625C27.023 35.921 26.465 35.493 25.895 35.279C25.8085 35.0416 25.6779 34.8228 25.51 34.634L33.293 26.842ZM33.293 25.427L23.826 34.905L23.842 34.905C23.979 34.905 25.036 34.943 25.036 36.11C25.036 36.11 26.424 36.153 26.424 37.496C27.804 37.496 27.615 38.678 27.615 38.678L37.074 29.208L33.293 25.427Z'
            fill='#2172DC'
          />
          <path d='M32.83 26.596L35.132 24.296L38.205 27.368L35.904 29.669L32.83 26.596Z' fill='#94BCF0' />
          <path
            d='M35.132 25.003L37.498 27.369L35.904 28.963L33.538 26.597L35.132 25.003ZM35.132 23.589L32.124 26.597L35.904 30.377L38.912 27.369L35.132 23.589Z'
            fill='#2172DC'
          />
          <path
            d='M7 17C7 16.7239 7.22386 16.5 7.5 16.5H9.5C9.77614 16.5 10 16.7239 10 17C10 17.2761 9.77614 17.5 9.5 17.5H7.5C7.22386 17.5 7 17.2761 7 17Z'
            fill='#2172DC'
          />
          <path
            d='M13 17C13 16.7239 13.2239 16.5 13.5 16.5H30.5C30.7761 16.5 31 16.7239 31 17C31 17.2761 30.7761 17.5 30.5 17.5H13.5C13.2239 17.5 13 17.2761 13 17Z'
            fill='#2172DC'
          />
          <path
            d='M7 21C7 20.7239 7.22386 20.5 7.5 20.5H9.5C9.77614 20.5 10 20.7239 10 21C10 21.2761 9.77614 21.5 9.5 21.5H7.5C7.22386 21.5 7 21.2761 7 21Z'
            fill='#2172DC'
          />
          <path
            d='M7 25C7 24.7239 7.22386 24.5 7.5 24.5H9.5C9.77614 24.5 10 24.7239 10 25C10 25.2761 9.77614 25.5 9.5 25.5H7.5C7.22386 25.5 7 25.2761 7 25Z'
            fill='#2172DC'
          />
          <path
            d='M7 29C7 28.7239 7.22386 28.5 7.5 28.5H9.5C9.77614 28.5 10 28.7239 10 29C10 29.2761 9.77614 29.5 9.5 29.5H7.5C7.22386 29.5 7 29.2761 7 29Z'
            fill='#2172DC'
          />
          <path
            d='M13.5 20.5C13.2239 20.5 13 20.7239 13 21C13 21.2761 13.2239 21.5 13.5 21.5H30.5C30.7761 21.5 31 21.2761 31 21C31 20.7239 30.7761 20.5 30.5 20.5H13.5Z'
            fill='#2172DC'
          />
          <path
            d='M13.5 24.5C13.2239 24.5 13 24.7239 13 25C13 25.2761 13.2239 25.5 13.5 25.5H30.5C30.7761 25.5 31 25.2761 31 25C31 24.7239 30.7761 24.5 30.5 24.5H13.5Z'
            fill='#2172DC'
          />
          <path
            d='M13.5 28.5C13.2239 28.5 13 28.7239 13 29C13 29.2761 13.2239 29.5 13.5 29.5H26.5C26.7761 29.5 27 29.2761 27 29C27 28.7239 26.7761 28.5 26.5 28.5H13.5Z'
            fill='#2172DC'
          />
        </g>
      </g>
    </svg>
  );
}

export default EditDocument;

import { ResponseDataAccounts } from '../interfaces/ResposeDataAccouts';

const accountsMock: ResponseDataAccounts = {
  status: 200,
  data: [
    {
      accountCuit: '30639844591',
      accountDescription: 'Empresa S.A.',
      accountNumber: '123456/78',
      accountType: 'CC',
      bankBCRAId: null,
      bankShortName: 'Banco 1',
      currencyCode: 'ARS',
      currencyDescription: '$',
      cbu: '23332488576332738329044',
    },
    {
      accountCuit: '30639844591',
      accountDescription: 'Empresa 2 S.A.',
      accountNumber: '123456/79',
      accountType: 'CC',
      bankBCRAId: null,
      bankShortName: 'Banco 2',
      currencyCode: 'ARS',
      currencyDescription: '$',
      cbu: '23332488576332738329044',
    },
    {
      accountCuit: '30639844591',
      accountDescription: 'Empresa 2 S.A.',
      accountNumber: '123456/80',
      accountType: 'CC',
      bankBCRAId: null,
      bankShortName: 'Banco 3',
      currencyCode: 'ARS',
      currencyDescription: '$',
      cbu: '23332488576332738329044',
    },
    {
      accountCuit: '30639844591',
      accountDescription: 'Empresa S.A.',
      accountNumber: '123456/81',
      accountType: 'CC',
      bankBCRAId: null,
      bankShortName: 'Banco 4',
      currencyCode: 'USD',
      currencyDescription: '$',
      cbu: '23332488576332738329044',
    },
    {
      accountCuit: '30639844591',
      accountDescription: 'Empresa S.A.',
      accountNumber: '123456/82',
      accountType: 'CC',
      bankBCRAId: null,
      bankShortName: 'Banco 5',
      currencyCode: 'USD',
      currencyDescription: '$',
      cbu: '23332488576332738329045',
    },
    {
      accountCuit: '30639844591',
      accountDescription: 'Empresa S.A.',
      accountNumber: '123456/83',
      accountType: 'CC',
      bankBCRAId: null,
      bankShortName: 'Banco 6',
      currencyCode: 'USD',
      currencyDescription: '$',
      cbu: '23332488576332738329045',
    },
  ],
};

export default accountsMock;

/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
import { Box, Grid, GridItem, Text, Tooltip, useToast } from '@chakra-ui/react';
// eslint-disable-next-line import/no-unresolved
import { AlertComponent, AlertDescription, AlertTitle } from '@wow/alert';
import { Card } from '@wow/card';
import { ErrorContentComponent, ErrorContentProps } from '@wow/error-content';
import { Input } from '@wow/input';
import { ToastComponent } from '@wow/toast';
import { WizardComponent } from '@wow/wizard';
import { useContext, useEffect, useState } from 'react';
import { Search } from 'react-feather';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { postSignersValidate } from '../../../services/api';
import operatorsMock from '../../__mocks__/operators';
import operatorsMock2 from '../../__mocks__/operators2';
import InfoIcon from '../../assets/icons/InfoIcon';
import SkeletonOnLoading from '../../commons/SkeletonOnLoading';
import ColumnSelectedSignatories from '../../components/ColumnSelectedSignatories';
import Footer from '../../components/footer';
import SignatorieCard from '../../components/SignatorieCard';
import routesNames from '../../constants/routesNames';
import AppContext from '../../context/Provider';
import { CommonErrorResponse } from '../../interfaces/IErrorResponse';
import { MutationSignersValidate } from '../../interfaces/IMutationSignersValidate';
import { Operator } from '../../interfaces/Operator';
import {
  ButtonsName,
  columnSignatoriesText,
  ErrorServiceText,
  SelectionSignatoriesText,
  steps,
  textTitleCards,
} from '../../text/GeneralText';
import handleWizard from '../../utils/handleWizard';
import evaluateStyles from '../../utils/stylesEvaluator';
import WizardSteps from '../../utils/wizardSteps';
import defaultStyles from './defaultStyles';
import { CardSectionHorizontal, Container, InputContainer, Operators, TextBold } from './styled';
import stylesPresets from './stylesPresets';

export type StylesType = typeof defaultStyles;

function SelectionSignatories() {
  const [inputValue, setInputValue] = useState('');
  const toast = useToast();
  const [alerta, setAlerta] = useState(true);
  const [selectedItems, setSelectedItems] = useState<Operator[]>([]);
  const [backgroundChange, setBackgroundChange] = useState(false);
  const navigate = useNavigate();
  const [heightWizard, setHeight] = useState<number>(264);
  const [widthWizard, setWidth] = useState<number>(305);
  const [zIndex, setZIndex] = useState(false);

  const [stylesAndBehaviorsColumnOne, setStylesAndBehaviorsColumnOne] = useState<StylesType>(defaultStyles);
  const [stylesAndBehaviorsColumnTwo, setStylesAndBehaviorsColumnTwo] = useState<StylesType>(defaultStyles);
  const [stylesAndBehaviorsColumnThree, setStylesAndBehaviorsColumnThree] = useState<StylesType>(defaultStyles);
  const {
    itemsColumnOne,
    setItemsColumnOne,
    itemsColumnTwo,
    setItemsColumnTwo,
    itemsColumnThree,
    setItemsColumnThree,
    data,
    setData,
    filteredData,
    setFilteredData,
    signersSchema,
    validateSchema,
    setShowErrorContent,
    isLoading,
    setIsLoading,
    fetchData,
    initialized,
    setInitialized,
    currentStep,
    setCurrentStep,
    modalWelcome,
  } = useContext(AppContext);

  const handleGoBackHome = () => {
    setShowErrorContent(false);
    return navigate(`/${routesNames.Home}`);
  };

  const errorContentProps: ErrorContentProps = {
    titleText: ErrorServiceText.ErrorContentTitleText,
    bodyText: ErrorServiceText.ErrorContentBodyText,
    linkText: ErrorServiceText.ErrorContentLinkText,
    isRouter: true,
    accessError: 'content',
    callbackAction: () => handleGoBackHome(),
  };
  useEffect(() => {
    validateSchema();
    setIsLoading(false);
    if (modalWelcome === true) {
      setFilteredData(operatorsMock);
      setInitialized(true);
    } else if (data.length === 0 && itemsColumnOne.length === 0 && itemsColumnTwo.length === 0 && itemsColumnThree.length === 0) {
      fetchData();
    }
  }, [data]);

  useEffect(() => {
    handleWizard(
      currentStep,
      modalWelcome,
      setFilteredData,
      setHeight,
      setWidth,
      setSelectedItems,
      setItemsColumnOne,
      setItemsColumnTwo,
      setItemsColumnThree,
      setZIndex,
      operatorsMock,
      operatorsMock2,
      navigate,
      routesNames,
    );
  }, [currentStep, initialized]);

  useEffect(() => {
    if (modalWelcome === false && (itemsColumnOne.length || itemsColumnTwo.length || itemsColumnThree.length)) {
      setBackgroundChange(true);
      setTimeout(() => {
        setBackgroundChange(false);
      }, 1000);
    }
  }, [itemsColumnOne, itemsColumnTwo, itemsColumnThree]);
  useEffect(() => {
    const styles = evaluateStyles(itemsColumnOne, itemsColumnTwo, itemsColumnThree, selectedItems, stylesPresets);

    setStylesAndBehaviorsColumnOne(styles.columnOne);
    setStylesAndBehaviorsColumnTwo(styles.columnTwo);
    setStylesAndBehaviorsColumnThree(styles.columnThree);
  }, [itemsColumnOne, itemsColumnTwo, itemsColumnThree, selectedItems]);

  const handleCheckboxChange = (operator: Operator) => {
    const isAlreadySelected = selectedItems.some(item => item.operatorId === operator.operatorId);

    if (isAlreadySelected) {
      setSelectedItems(prev => prev.filter(item => item.operatorId !== operator.operatorId));
    } else {
      setSelectedItems(prev => [...prev, operator]);
    }
  };

  const addSignersToColumn = (setItemsColumn: React.Dispatch<React.SetStateAction<Operator[]>>) => {
    setItemsColumn(prevItems => {
      const updatedItems = [...prevItems, ...selectedItems];

      return updatedItems.sort((a, b) => a.firstName.localeCompare(b.firstName) || a.lastName.localeCompare(b.lastName));
    });

    setData(prevData => {
      const remainingData = prevData.filter(item => !selectedItems.some(selectedItem => selectedItem.operatorId === item.operatorId));
      setFilteredData(remainingData);
      return remainingData;
    });

    setSelectedItems([]);
  };

  const removeSignersFromColumn = (operator: Operator, setItemsColumn: React.Dispatch<React.SetStateAction<Operator[]>>) => {
    setItemsColumn(prevItems => prevItems.filter(item => item.operatorId !== operator.operatorId));
    setData(prevData => {
      const isAlreadyInData = prevData.some(item => item.operatorId === operator.operatorId);

      if (!isAlreadyInData) {
        const updatedData = [...prevData, operator].sort(
          (a, b) => a.firstName.localeCompare(b.firstName) || a.lastName.localeCompare(b.lastName),
        );
        setFilteredData(updatedData);
        return updatedData;
      }
      return prevData;
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const regex = /^[a-zA-ZñÑáéíóúÁÉÍÓÚüÜ\s]*$/;

    if (regex.test(value)) {
      setInputValue(value);

      if (value.length >= 3) {
        const searchTerms = value
          .toLowerCase()
          .split(' ')
          .filter(term => term.length > 0);

        const filtered = data.filter(operator =>
          searchTerms.every(term => operator.firstName.toLowerCase().includes(term) || operator.lastName.toLowerCase().includes(term)),
        );
        setFilteredData(filtered.length > 0 ? filtered : []);
      } else {
        setFilteredData(data);
      }
    }
  };

  const getTitlesToShow = () => {
    const { title } = textTitleCards;
    if (!itemsColumnOne.length && !itemsColumnTwo.length && !itemsColumnThree.length) {
      return [title.find(t => t.id === 1)];
    }
    if (itemsColumnThree.length > 0) {
      return [title.find(t => t.id === 4)];
    }
    if (itemsColumnTwo.length > 0) {
      return [title.find(t => t.id === 3)];
    }
    if (itemsColumnOne.length > 0) {
      return [title.find(t => t.id === 2)];
    }
    return [];
  };
  const titlesToShow = getTitlesToShow();

  const openToast = (status, title, description, ctaOnClick = null) => {
    toast({
      duration: 10000,
      position: 'bottom-left',
      status,
      title,
      description,
      render: props => (
        <ToastComponent
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          isCtaLabel={status === 'error'}
          ctaLabel={status === 'error' ? 'Ir a Tus esquemas' : ''}
          ctaOnClick={ctaOnClick}
        />
      ),
    });
  };

  const sibSchema = () => {
    return navigate(`/${routesNames.Home}`);
  };

  const {
    mutate,
    error: mutationError,
    isSuccess: mutationIsSuccess,
    isLoading: mutationIsLoading,
    reset,
  } = useMutation<void, Error, MutationSignersValidate[]>(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    async (signers): Promise<void> => {
      const result = await postSignersValidate(signers);
      return result;
    },
  );

  if (mutationError && (mutationError as CommonErrorResponse).response?.status) {
    const { status } = (mutationError as CommonErrorResponse).response;

    if (status === 409) {
      openToast('error', 'Ir a Tus esquemas', 'Ya tenés un esquema con estos firmantes. Editalo desde la sección Tus esquemas.', () =>
        sibSchema(),
      );
      reset();
    }
    if (status === 500) {
      setShowErrorContent(true);
      return <ErrorContentComponent ErrorContentProps={errorContentProps} />;
    }
  }

  if (mutationIsSuccess) {
    navigate(`/${routesNames.Account}`);
    return null;
  }

  const handleSubmit = values => {
    mutate(values);
  };

  const isButtonDisabled = !(itemsColumnOne.length > 0 || itemsColumnTwo.length > 0 || itemsColumnThree.length > 0);

  const finishWizard = () => {
    setFilteredData(operatorsMock);
    setSelectedItems([]);
    setItemsColumnOne([]);
    setItemsColumnTwo([]);
    setItemsColumnThree([]);
    localStorage.setItem('esquema-wizard', 'true');
    return navigate(`/${routesNames.Home}`);
  };
  if (isLoading) return <SkeletonOnLoading />;
  return (
    <>
      <Container backgroundChange={backgroundChange}>
        <Box mt='1.5rem'>
          <Grid id='container' templateColumns='repeat(12, 1fr)' gap={6}>
            <GridItem className='headlineWithIcon' colSpan={12}>
              <Text textStyle='h1'>{SelectionSignatoriesText.selectionSignatoriesTitle}</Text>
              <div style={{ marginLeft: '16px', position: 'relative', display: 'inline-block' }}>
                <Tooltip
                  hasArrow
                  placement='right'
                  label={<Text whiteSpace='pre-line'>{SelectionSignatoriesText.selectionSignatoriesTooltip}</Text>}
                  backgroundColor='#2F3034'
                  padding='8px'
                  borderRadius='8px'
                  fontFamily='Inter'
                  fontSize='12px'
                  fontStyle='normal'
                  fontWeight={400}
                  textAlign='center'
                >
                  <Box w='30px'>
                    <InfoIcon />
                  </Box>
                </Tooltip>
              </div>
            </GridItem>
            <GridItem colSpan={12}>
              <Text textStyle='bodyMd'>
                {SelectionSignatoriesText.selectionSignatoriesSubtitle}
                <Operators>{SelectionSignatoriesText.selectionSignatoriesSubtitleBold}</Operators>
              </Text>
            </GridItem>
            {alerta && (
              <GridItem maxW='1250px' className='headline' colSpan={12}>
                <AlertComponent id='alert-id' variant='information' onClose={() => setAlerta(false)}>
                  <AlertTitle>{SelectionSignatoriesText.selectionSignatoriesAlertTitle}</AlertTitle>
                  <AlertDescription>{SelectionSignatoriesText.selectionSignatoriesAlertSubtitle}</AlertDescription>
                </AlertComponent>
              </GridItem>
            )}
            <GridItem w='315px' overflow='auto' colSpan={3}>
              <Card id='cardListSignatories' className={`cardListSignatories ${zIndex ? 'zindex' : ''}`}>
                <CardSectionHorizontal>
                  <InputContainer>
                    <Input
                      isDisabled={false}
                      isInvalid={false}
                      inputSize='md'
                      inputVariant='IconLeft'
                      placeholder={SelectionSignatoriesText.selectionSignatoriesInputSearchPlaceholder}
                      iconInput={{
                        icon: <Search />,
                        iconVariant: 'dummy',
                      }}
                      value={inputValue}
                      onChange={handleInputChange}
                      errorMessage='Error Message'
                      type='text'
                    />
                  </InputContainer>
                  {filteredData.length > 0 &&
                    filteredData.map(operator => (
                      <SignatorieCard
                        key={operator.operatorId}
                        firstName={operator.firstName}
                        lastName={operator.lastName}
                        operator={operator}
                        isSelected={selectedItems.some(item => item.operatorId === operator.operatorId)}
                        onCheckboxChange={handleCheckboxChange}
                      />
                    ))}
                </CardSectionHorizontal>
              </Card>
            </GridItem>
            <Box display='grid' id='wizard3' p='0.5rem'>
              <GridItem
                className={`card-title-container ${backgroundChange ? 'transitioning' : ''}`}
                colSpan={9}
                gridColumnStart={4}
                gridRowStart={alerta ? 4 : 3}
              >
                {modalWelcome === true ? (
                  <Box bgColor='#e8f1fc' id='firm' p='0.5rem' maxW='fit-content' overflow='visible'>
                    {titlesToShow.map(title => (
                      <Text key={title.id} textStyle='bodyLg' whiteSpace='nowrap'>
                        {title.parts.map((part, index) =>
                          part.bold ? <TextBold key={index}>{part.text}</TextBold> : <span key={index}>{part.text}</span>,
                        )}
                      </Text>
                    ))}
                  </Box>
                ) : (
                  <>
                    {titlesToShow.map(title => (
                      <Text key={title.id} paddingLeft='1rem' textStyle='bodyLg'>
                        {title.parts.map((part, index) =>
                          part.bold ? <TextBold key={index}>{part.text}</TextBold> : <span key={index}>{part.text}</span>,
                        )}
                      </Text>
                    ))}
                    <Box />
                  </>
                )}
              </GridItem>

              <GridItem
                w='300px'
                p='5px'
                id='column-one'
                className='card-container'
                colSpan={3}
                gridColumnStart={4}
                gridRowStart={alerta ? 4 : 3}
              >
                <ColumnSelectedSignatories
                  titleText={columnSignatoriesText.columnOne.title}
                  data={itemsColumnOne}
                  addSigners={() => addSignersToColumn(setItemsColumnOne)}
                  removeSigners={operator => removeSignersFromColumn(operator, setItemsColumnOne)}
                  dataTestIdAddSignerButton='add-signers-button-column-one'
                  dataTestIdRemoveSignerButton='remove-signers-button-column-one'
                  dataTestIdColumn='signers-column-one'
                  checkbox={selectedItems}
                  styles={stylesAndBehaviorsColumnOne}
                  deleteButton={itemsColumnTwo.length === 0 || (itemsColumnOne.length > 1 && itemsColumnTwo.length > 0)}
                />
              </GridItem>
              <GridItem
                w='300px'
                p='5px'
                id='column-two'
                className='card-container'
                colSpan={3}
                gridColumnStart={7}
                gridRowStart={alerta ? 4 : 3}
              >
                <ColumnSelectedSignatories
                  titleText={columnSignatoriesText.columnTwo.title}
                  data={itemsColumnTwo}
                  addSigners={() => addSignersToColumn(setItemsColumnTwo)}
                  removeSigners={operator => removeSignersFromColumn(operator, setItemsColumnTwo)}
                  dataTestIdAddSignerButton='add-signers-button-column-two'
                  dataTestIdRemoveSignerButton='remove-signers-button-column-two'
                  dataTestIdColumn='signers-column-two'
                  checkbox={selectedItems}
                  styles={stylesAndBehaviorsColumnTwo}
                  deleteButton={itemsColumnThree.length === 0 || (itemsColumnTwo.length > 1 && itemsColumnThree.length > 0)}
                />
              </GridItem>
              <GridItem
                w='300px'
                p='5px'
                id='column-three'
                className='card-container'
                colSpan={3}
                gridColumnStart={10}
                gridRowStart={alerta ? 4 : 3}
              >
                <ColumnSelectedSignatories
                  titleText={columnSignatoriesText.columnThree.title}
                  data={itemsColumnThree}
                  addSigners={() => addSignersToColumn(setItemsColumnThree)}
                  removeSigners={operator => removeSignersFromColumn(operator, setItemsColumnThree)}
                  dataTestIdAddSignerButton='add-signers-button-column-three'
                  dataTestIdRemoveSignerButton='remove-signers-button-column-three'
                  dataTestIdColumn='signers-column-three'
                  checkbox={selectedItems}
                  styles={stylesAndBehaviorsColumnThree}
                  deleteButton
                />
              </GridItem>
            </Box>
          </Grid>
          <Footer
            fetchData={() => fetchData()}
            handleSubmit={() => handleSubmit(signersSchema)}
            isLoading={mutationIsLoading}
            loadingText={ButtonsName.continue}
            steps={steps.stepOne}
            isDisabled={isButtonDisabled}
          />
        </Box>{' '}
      </Container>
      <WizardComponent
        id='wizard'
        steps={WizardSteps}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        initialized={initialized}
        setInitialized={setInitialized}
        width={widthWizard}
        height={heightWizard}
        offset={20}
        initialLabel='Comenzar'
        finishLabel='Finalizar'
        onFinish={finishWizard}
        onPrevious={() => console.log('prev')}
        onNext={() => console.log('next')}
        hasWelcome={false}
      />
    </>
  );
}

export default SelectionSignatories;

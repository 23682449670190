import Bank from '../../assets/images/Bank';
import Moon from '../../assets/images/Moon';
import Rocket from '../../assets/images/Rocket';
import { EmptysBanksAccountsCandidatesText, EmptyText, EmptyValidatePreConditionsText } from '../../text/GeneralText';

export default {
  SIGNATURE_SCHEME: {
    NO_DATA: {
      title: EmptyText.emptyTitle,
      subtitle: EmptyText.emptySubtitle,
      picture: <Rocket />,
      hasButton: true,
      buttonLabel: EmptyText.emptyButton,
    },
  },
  HOME: {
    ACCESS_NO_DATA: {
      title: EmptyValidatePreConditionsText.emptyTitle,
      subtitle: EmptyValidatePreConditionsText.emptySubtitle,
      picture: <Bank />,
      hasButton: true,
      buttonLabel: EmptyValidatePreConditionsText.emptyButton,
    },
  },
  ACCOUNT: {
    ACCESS_NO_DATA: {
      title: EmptysBanksAccountsCandidatesText.emptyTitle,
      subtitle: EmptysBanksAccountsCandidatesText.emptySubtitle,
      picture: <Moon />,
      hasButton: true,
      buttonLabel: EmptysBanksAccountsCandidatesText.emptyButton,
    },
  },
};

import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';

import Breadcrumb from '../components/breadcrumb/breadcrumb';
import AppContext from '../context/Provider';
import Panel from './styled';

function SignatureSchemeLayout() {
  const { showErrorContent } = useContext(AppContext);
  return (
    <Panel>
      {!showErrorContent && <Breadcrumb />}
      {/* Footer */}
      <Outlet />
    </Panel>
  );
}

export default SignatureSchemeLayout;
